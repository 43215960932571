<template>
  <div id="form-helper" class="pa-5">
    <div class="row">
      <div class="col-6">
        <div class="card card-custom">
          <div class="card-body">
            <FormHelper
              v-if="1"
              ref="form"
              :key="formHelperKey"
              v-model="values"
              :form="form"
              :config="configNew"
              @action="onAction"
              @change="onChange"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom">
          <div class="card-body">
            <h5>Values</h5>
            <pre>{{ values }}</pre>
            <b-form-checkbox v-model="stackLabels" switch>
              Stack Labels
            </b-form-checkbox>
            <b-form-checkbox
              v-model="disableFields"
              switch
              @change="setDisabled"
            >
              Disable
            </b-form-checkbox>
            <button class="btn btn-primary" @click="validate">Validate</button>
            <button class="btn btn-primary" @click="setValue">Set Value</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper";
import { SET_CUSTOM_VARIABLES } from "@/core/services/store/variables_v1.module";

import Languages from "@/components/General/Languages/languages";
import { demoCategories } from "@/components/Tools/FormHelper/demoCategories";

export default {
  components: {
    FormHelper
  },
  props: {},
  data() {
    return {
      stackLabels: false,
      disableFields: false,
      formHelperKey: 1,
      values: {
        jsonObject: {
          json: [
            {
              name: "test",
              wert: "1234"
            }
          ]
        },
        array: [],
        text: {
          test: {
            text1: ""
          }
        },
        num: 123,
        password: "123",
        select: "",
        multiselect: ["test1", "test2"],
        checkbox: true,
        file: [],
        editor: "",
        condition: {
          type: "group",
          operator: "and",
          children: [
            {
              type: "condition",
              field: "{{config.mailconfig}}",
              operator: "==",
              value: "test",
              valid: true
            }
          ],
          valid: true
        },
        code: JSON.stringify(
          [
            {
              name: "test",
              wert: "1234"
            },
            {
              name: "test",
              wert: "1234"
            }
          ],
          null,
          2
        )
      },
      config: {
        title: "Basiselemente",
        labelStacked: false,
        snippetPrefix: "formHelper.test",
        enableVariables: true,
        customVariables: ["demo"]
      },
      form: [
        {
          label: "label",
          type: "condition",
          name: "condition"
        },
        {
          type: "json",
          label: "json",
          name: "jsonObject.json",
          // default: {
          //   test1: "Test 1234 Test 5678",
          //   laengererName: true,
          //   "wir freuen uns": "wir freuen uns",
          //   "schauen wir mal was wird": "was wird",
          //   test5: 123,
          //   test6: "123",
          // },
          returnJson: false,
          fields: [
            {
              name: "name"
            },
            {
              name: "wert"
            }
          ]
        },
        {
          label: "Array",
          type: "array",
          name: "array",
          validations: {
            minLength: 2,
            maxLength: 4
          }
        },
        {
          label: "Image Upload",
          type: "image",
          name: "image",
          defaultImage:
            "https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
          ratio: 16 / 9
        },
        {
          label: "label",
          name: "text.test.text1",
          type: "text",
          placeholder: "placeholder",
          strict: false,
          // prepend: "prepend",
          // prependIcon: "fal fa-face-awesome",
          // append: "append",
          // appendIcon: "fal fa-face-explode",
          help: "yedi",
          // copy: true,
          enableVariables: true,
          default: "",
          validations: {
            required: false
            // minLength: 3,
            // alphaNumSpace: true
          },
          dependsOn: [
            {
              name: "select",
              notValues: ["test2", "test3"]
            }
          ]
        },
        {
          label: "label",
          name: "text.test.text2",
          type: "text",
          placeholder: "placeholder",
          enableTypecast: true,
          // prepend: "prepend",
          // prependIcon: "fal fa-face-awesome",
          // append: "append",
          // appendIcon: "fal fa-face-explode",
          help: "yedi",
          // copy: true,
          enableVariables: true,
          default: "",
          validations: {
            required: false
            // minLength: 3,
            // alphaNumSpace: true
          },
          dependsOn: [
            {
              name: "select",
              notValues: ["test2", "test3"]
            }
          ]
        },
        {
          label: "labelNum",
          name: "num",
          type: "number",
          placeholder: "placeholderNum",
          validations: {
            required: true,
            integer: true
          }
        },
        {
          label: "labelNum",
          name: "num2",
          type: "number",
          placeholder: "placeholderNum",
          validations: {
            required: true,
            integer: true
          },
          enableTypecast: true
        },
        {
          type: "password",
          label: "Password",
          name: "password",
          validations: {
            required: true,
            minLength: 8,
            maxLength: 20
          }
        },
        {
          type: "select",
          label: "selectFeld",
          name: "select",
          placeholder: "select",
          clearable: true,
          default: "test1",
          options: [
            {
              group: "Test",
              value: "test1",
              info: "Dit is die Info dafür"
            },
            {
              group: "Test",
              value: "test2"
            },
            {
              group: "Test2",
              value: "test3"
            }
          ],
          validations: {
            required: true
          }
        },
        {
          type: "select",
          label: "selectFeldChildren",
          name: "selectChildren",
          placeholder: "select",
          clearable: true,
          options: demoCategories,
          validations: {
            required: true
          }
        },
        {
          type: "hidden",
          name: "hidden",
          value: true
        },
        {
          type: "multiselect",
          label: "multiSelectFeld",
          name: "multiselect",
          placeholder: "select",
          clearable: true,
          options: [
            "test1",
            "test2",
            "test3",
            "test1test1test1test1test1test1test1test1",
            "test2test2test2test2test2test2test2test2",
            "test3test3test3test3test3test3test3test3",
            "test4",
            "test5",
            "test6",
            "test7",
            "test8",
            "test9",
            "test10",
            "test11",
            "test12"
          ],
          validations: {
            required: true,
            minLength: 2,
            maxLength: 2
          }
        },
        {
          type: "checkbox",
          name: "checkbox",
          label: {
            de: "Geil wa?",
            en: "Cool bro"
          },
          validations: {
            required: true
          },
          dependsOn: [
            {
              name: "multiselect",
              values: ["test2"]
            }
          ],
          help: "Test",
          switch: false
        },
        {
          type: "file",
          name: "file",
          accept: [".pdf", ".csv"],
          size: 5000,
          multiple: true
        },
        {
          type: "alert",
          color: "primary",
          // icon: "fal fa-circle-check",
          label: "Test ohne Icon"
        },
        {
          type: "alert",
          color: "warning",
          name: "alert2",
          icon: "fal fa-triangle-exclamation",
          label: "Warnung! Das ist ein Test!",
          count: 0,
          link: this.$router.resolve({ name: "projectWorkflows" }).href,
          target: "_blank"
        },
        {
          type: "alert",
          name: "errorAlert",
          color: "danger",
          icon: "fal fa-circle-xmark",
          label: "Error!"
        },
        {
          type: "textarea",
          default: "<h1>H1</h1>",
          name: "textarea",
          label: "label"
        },
        {
          type: "texteditor",
          label: "label",
          name: "editor",
          default: "<h1>H1</h1>",
          validations: {
            maxLength: 10
          }
        },
        {
          type: "datetime",
          name: "datetime",
          label: "label"
        },
        {
          type: "range",
          name: "range",
          label: "label",
          // sliderLabel: "range",
          prependIcon: "fal fa-face-zany",
          min: 0,
          max: 100,
          step: 1
        },
        {
          type: "time",
          name: "time",
          label: "label"
        },
        {
          type: "date",
          name: "date",
          label: "label"
        },
        {
          type: "action",
          action: {
            method: "GET",
            url: process.env.VUE_APP_API_ADMIN + "/languages"
          },
          label: "label",
          buttonLabel: "actionLabel"
        },
        {
          label: "label",
          name: "code",
          type: "code",
          lang: "json",
          style: "dark",
          minHeight: 200,
          // jsonPathPrefix: "output",
          jsonPathSuffix: "test"
        }
      ]
    };
  },
  computed: {
    configNew: function () {
      let config = this.config;
      config.labelStacked = this.stackLabels;
      return config;
    }
  },
  watch: {
    stackLabels: function () {
      this.formHelperKey++;
    },
    values: {
      deep: true,
      handler: function () {
        // Log values
      }
    }
  },
  mounted() {
    this.setDemoVariables();
    this.inrementCounter();
  },
  methods: {
    setValue() {
      this.values.text.test.text1 = (Math.random() + 1)
        .toString(36)
        .substring(7);
    },
    validate() {},
    setDemoVariables() {
      const demoSet = {
        name: "demo",
        variables: [
          {
            text: "demoFlat",
            value: "test123"
          },
          {
            text: "demoNested",
            value: {
              level1: {
                level2: {
                  level3: "test123"
                }
              }
            }
          }
        ]
      };
      this.$store.dispatch("variables/" + SET_CUSTOM_VARIABLES, demoSet);
    },
    async demoAction() {
      await Languages.getAll();
    },
    onAction() {},
    onChange() {},
    setDisabled() {
      this.form.forEach((field, index) => {
        this.$set(this.form[index], "disabled", this.disableFields);
      });
    },
    inrementCounter() {
      let me = this;
      setTimeout(() => {
        let alert = me.form.find(f => f.name === "alert2");
        alert.count += 1;
        if (alert.count < 10) this.inrementCounter();
      }, 1000);
    }
  }
};
</script>
