export const demoCategories = {
  "Tiere & Tierbedarf": {
    label: "Tiere & Tierbedarf",
    value: "1",
    children: {
      "Lebende Tiere": {
        label: "Lebende Tiere",
        value: "2"
      },
      Haustierbedarf: {
        label: "Haustierbedarf",
        value: "3",
        children: {
          Vogelbedarf: {
            label: "Vogelbedarf",
            value: "4",
            children: {
              Vogelkäfigzubehör: {
                label: "Vogelkäfigzubehör",
                value: "5",
                children: {
                  "Vogelbäder für Vogelkäfige": {
                    label: "Vogelbäder für Vogelkäfige",
                    value: "6"
                  },
                  "Futter- & Wasserspender für Vogelkäfige": {
                    label: "Futter- & Wasserspender für Vogelkäfige",
                    value: "7"
                  }
                }
              },
              "Vogelkäfige & -ständer": {
                label: "Vogelkäfige & -ständer",
                value: "8"
              },
              Vogelfutter: {
                label: "Vogelfutter",
                value: "9"
              },
              "Spielvorrichtungen für Vögel": {
                label: "Spielvorrichtungen für Vögel",
                value: "10"
              },
              "Vogelleitern & -sitzstangen": {
                label: "Vogelleitern & -sitzstangen",
                value: "11"
              },
              Vogelspielzeug: {
                label: "Vogelspielzeug",
                value: "12"
              },
              "Snacks für Vögel": {
                label: "Snacks für Vögel",
                value: "13"
              }
            }
          },
          Katzenbedarf: {
            label: "Katzenbedarf",
            value: "14",
            children: {
              Katzenbekleidung: {
                label: "Katzenbekleidung",
                value: "15"
              },
              Katzenbetten: {
                label: "Katzenbetten",
                value: "16"
              },
              Katzenfutter: {
                label: "Katzenfutter",
                value: "17",
                children: {
                  "Nicht verschreibungspflichtige Katzennahrung": {
                    label: "Nicht verschreibungspflichtige Katzennahrung",
                    value: "18"
                  },
                  "Verschreibungspflichtige Katzennahrung": {
                    label: "Verschreibungspflichtige Katzennahrung",
                    value: "19"
                  }
                }
              },
              Katzenmöbel: {
                label: "Katzenmöbel",
                value: "20"
              },
              "Zubehör für Katzenmöbel": {
                label: "Zubehör für Katzenmöbel",
                value: "21"
              },
              Katzenstreu: {
                label: "Katzenstreu",
                value: "22"
              },
              "Einlagen für Katzentoilette": {
                label: "Einlagen für Katzentoilette",
                value: "23"
              },
              "Vorleger für Katzentoiletten": {
                label: "Vorleger für Katzentoiletten",
                value: "24"
              },
              Katzentoiletten: {
                label: "Katzentoiletten",
                value: "25"
              },
              Katzenspielzeug: {
                label: "Katzenspielzeug",
                value: "26"
              },
              "Leckerbissen für Katzen": {
                label: "Leckerbissen für Katzen",
                value: "27"
              }
            }
          },
          Hundebedarf: {
            label: "Hundebedarf",
            value: "28",
            children: {
              Hundebekleidung: {
                label: "Hundebekleidung",
                value: "29"
              },
              Hundebetten: {
                label: "Hundebetten",
                value: "30"
              },
              "Einlagen für Hundewindeln": {
                label: "Einlagen für Hundewindeln",
                value: "31"
              },
              Hundewindeln: {
                label: "Hundewindeln",
                value: "32"
              },
              Hundefutter: {
                label: "Hundefutter",
                value: "33",
                children: {
                  "Nicht verschreibungspflichtige Hundenahrung": {
                    label: "Nicht verschreibungspflichtige Hundenahrung",
                    value: "34"
                  },
                  "Verschreibungspflichtige Hundenahrung": {
                    label: "Verschreibungspflichtige Hundenahrung",
                    value: "35"
                  }
                }
              },
              Hundehütten: {
                label: "Hundehütten",
                value: "36"
              },
              "Zubehör für Hundezwinger & -ausläufe": {
                label: "Zubehör für Hundezwinger & -ausläufe",
                value: "37"
              },
              "Hundezwinger & -ausläufe": {
                label: "Hundezwinger & -ausläufe",
                value: "38"
              },
              Hundespielzeug: {
                label: "Hundespielzeug",
                value: "39"
              },
              Hundelaufbänder: {
                label: "Hundelaufbänder",
                value: "40"
              },
              "Leckerbissen für Hunde": {
                label: "Leckerbissen für Hunde",
                value: "41"
              }
            }
          },
          Aquaristik: {
            label: "Aquaristik",
            value: "42",
            children: {
              "Schläuche & Rohre für Aquarien & Teiche": {
                label: "Schläuche & Rohre für Aquarien & Teiche",
                value: "43"
              },
              "Ausströmersteine & Diffusoren für Aquarien": {
                label: "Ausströmersteine & Diffusoren für Aquarien",
                value: "44"
              },
              "Zubehör für Aquariumreinigung": {
                label: "Zubehör für Aquariumreinigung",
                value: "45"
              },
              Aquariumdekoration: {
                label: "Aquariumdekoration",
                value: "46"
              },
              Aquariumfilter: {
                label: "Aquariumfilter",
                value: "47"
              },
              "Fischnetze für Aquarien": {
                label: "Fischnetze für Aquarien",
                value: "48"
              },
              "Bodengrund für Aquarien": {
                label: "Bodengrund für Aquarien",
                value: "49"
              },
              Aquariumbeleuchtung: {
                label: "Aquariumbeleuchtung",
                value: "50"
              },
              Überlaufkästen: {
                label: "Überlaufkästen",
                value: "51"
              },
              Aquariumständer: {
                label: "Aquariumständer",
                value: "52"
              },
              "Temperaturregler für Aquarien": {
                label: "Temperaturregler für Aquarien",
                value: "53"
              },
              Wasseraufbereitungsgeräte: {
                label: "Wasseraufbereitungsgeräte",
                value: "54"
              },
              Aquarien: {
                label: "Aquarien",
                value: "55"
              },
              Wasserpflanzendünger: {
                label: "Wasserpflanzendünger",
                value: "56"
              },
              Fischfutterautomaten: {
                label: "Fischfutterautomaten",
                value: "57"
              },
              Fischfutter: {
                label: "Fischfutter",
                value: "58"
              }
            }
          },
          "Agility-Ausrüstung": {
            label: "Agility-Ausrüstung",
            value: "59"
          },
          "Bügel für Haustierbekleidung": {
            label: "Bügel für Haustierbekleidung",
            value: "60"
          },
          "Zubehör für Haustierbetten": {
            label: "Zubehör für Haustierbetten",
            value: "61"
          },
          "Glocken & Anhänger": {
            label: "Glocken & Anhänger",
            value: "62"
          },
          "Fitnessmonitor für Haustiere": {
            label: "Fitnessmonitor für Haustiere",
            value: "63",
            children: {
              "Glukosemessgeräte für Haustiere": {
                label: "Glukosemessgeräte für Haustiere",
                value: "64"
              },
              "Schrittzähler für Haustiere": {
                label: "Schrittzähler für Haustiere",
                value: "65"
              },
              "Thermometer für Haustiere": {
                label: "Thermometer für Haustiere",
                value: "66"
              }
            }
          },
          Napfunterlagen: {
            label: "Napfunterlagen",
            value: "67"
          },
          Napfständer: {
            label: "Napfständer",
            value: "68"
          },
          "Futter- & Wasserspender": {
            label: "Futter- & Wasserspender",
            value: "69"
          },
          "Zubehör für Haustiertragetaschen und -boxen": {
            label: "Zubehör für Haustiertragetaschen und -boxen",
            value: "70"
          },
          "Transportboxen & -taschen": {
            label: "Transportboxen & -taschen",
            value: "71"
          },
          "Halsbänder & Geschirre": {
            label: "Halsbänder & Geschirre",
            value: "72"
          },
          Begrenzungssysteme: {
            label: "Begrenzungssysteme",
            value: "73"
          },
          "Zubehör für Haustierklappen": {
            label: "Zubehör für Haustierklappen",
            value: "74"
          },
          Haustiertüren: {
            label: "Haustiertüren",
            value: "75"
          },
          "Augentropfen für Haustiere": {
            label: "Augentropfen für Haustiere",
            value: "76"
          },
          "Erste-Hilfe-Koffer für Haustiere": {
            label: "Erste-Hilfe-Koffer für Haustiere",
            value: "77"
          },
          "Floh- & Zeckenschutzmittel": {
            label: "Floh- & Zeckenschutzmittel",
            value: "78"
          },
          Tierfutterbehälter: {
            label: "Tierfutterbehälter",
            value: "79"
          },
          Tierfutterportionierer: {
            label: "Tierfutterportionierer",
            value: "80"
          },
          Haustierpflegeartikel: {
            label: "Haustierpflegeartikel",
            value: "81",
            children: {
              "Kämme & Bürsten zur Fellpflege": {
                label: "Kämme & Bürsten zur Fellpflege",
                value: "82"
              },
              "Düfte & Deosprays für Haustiere": {
                label: "Düfte & Deosprays für Haustiere",
                value: "83"
              },
              Tierhaarschneider: {
                label: "Tierhaarschneider",
                value: "84"
              },
              "Haartrockner für Haustiere": {
                label: "Haartrockner für Haustiere",
                value: "85"
              },
              "Nagellack für Haustiere": {
                label: "Nagellack für Haustiere",
                value: "86"
              },
              "Nagelpflege für Haustiere": {
                label: "Nagelpflege für Haustiere",
                value: "87"
              },
              "Shampoos & Spülungen zur Fellpflege": {
                label: "Shampoos & Spülungen zur Fellpflege",
                value: "88"
              },
              "Feuchttücher für Haustiere": {
                label: "Feuchttücher für Haustiere",
                value: "89"
              }
            }
          },
          "Zubehör für Haustier-Heizkissen": {
            label: "Zubehör für Haustier-Heizkissen",
            value: "90"
          },
          "Wärmekissen für Haustiere": {
            label: "Wärmekissen für Haustiere",
            value: "91"
          },
          Erkennungsmarken: {
            label: "Erkennungsmarken",
            value: "92"
          },
          Verlängerungsleinen: {
            label: "Verlängerungsleinen",
            value: "93"
          },
          Leinen: {
            label: "Leinen",
            value: "94"
          },
          "Medizinische Halsbänder für Haustiere": {
            label: "Medizinische Halsbänder für Haustiere",
            value: "95"
          },
          "Medizinisches Klebeband & Verbandsmaterial für Haustiere": {
            label: "Medizinisches Klebeband & Verbandsmaterial für Haustiere",
            value: "96"
          },
          Tiermedikamente: {
            label: "Tiermedikamente",
            value: "97"
          },
          Maulkörbe: {
            label: "Maulkörbe",
            value: "98"
          },
          "Mundpflege für Haustiere": {
            label: "Mundpflege für Haustiere",
            value: "99"
          },
          "Spielgehege für Haustiere": {
            label: "Spielgehege für Haustiere",
            value: "100"
          },
          "Treppen & Rampen für Haustiere": {
            label: "Treppen & Rampen für Haustiere",
            value: "101"
          },
          "Pet-Stroller": {
            label: "Pet-Stroller",
            value: "102"
          },
          "Sonnenschutz für Haustiere": {
            label: "Sonnenschutz für Haustiere",
            value: "103"
          },
          "Hilfsmittel für Tierausbildung": {
            label: "Hilfsmittel für Tierausbildung",
            value: "104",
            children: {
              "Clicker & Belohnungsspielzeug für Tierausbildung": {
                label: "Clicker & Belohnungsspielzeug für Tierausbildung",
                value: "105"
              },
              "Pad-Unterlagen für Tierausbildung": {
                label: "Pad-Unterlagen für Tierausbildung",
                value: "106"
              },
              "Bodenschutz & Unterlagen": {
                label: "Bodenschutz & Unterlagen",
                value: "107"
              },
              "Sprays & Lösungen für Tierausbildung": {
                label: "Sprays & Lösungen für Tierausbildung",
                value: "108"
              }
            }
          },
          "Vitamine & Nahrungsergänzungsmittel für Haustiere": {
            label: "Vitamine & Nahrungsergänzungsmittel für Haustiere",
            value: "109"
          },
          "Spender & Halterungen für Kotbeutel": {
            label: "Spender & Halterungen für Kotbeutel",
            value: "110"
          },
          Kotbeutel: {
            label: "Kotbeutel",
            value: "111"
          },
          "Entsorgungssysteme & -werkzeug für Tierkot": {
            label: "Entsorgungssysteme & -werkzeug für Tierkot",
            value: "112"
          },
          "Reptilien- & Amphibienbedarf": {
            label: "Reptilien- & Amphibienbedarf",
            value: "113",
            children: {
              "Reptilien- & Amphibienfutter": {
                label: "Reptilien- & Amphibienfutter",
                value: "114"
              },
              "Zubehör für Terrarien": {
                label: "Zubehör für Terrarien",
                value: "115"
              },
              "Heizung & Beleuchtung für Terrarien": {
                label: "Heizung & Beleuchtung für Terrarien",
                value: "116"
              },
              Terrarien: {
                label: "Terrarien",
                value: "117"
              },
              "Bodengrund für Reptilien & Amphibien": {
                label: "Bodengrund für Reptilien & Amphibien",
                value: "118"
              }
            }
          },
          Kleintierzubehör: {
            label: "Kleintierzubehör",
            value: "119",
            children: {
              "Decken & Streu für Kleintiere": {
                label: "Decken & Streu für Kleintiere",
                value: "120"
              },
              "Futter für Kleintiere": {
                label: "Futter für Kleintiere",
                value: "121"
              },
              "Zubehör für Lebensräume von Kleintieren": {
                label: "Zubehör für Lebensräume von Kleintieren",
                value: "122"
              },
              "Lebensräume & Gehege für Kleintiere": {
                label: "Lebensräume & Gehege für Kleintiere",
                value: "123"
              },
              "Leckerli für Kleintiere": {
                label: "Leckerli für Kleintiere",
                value: "124"
              }
            }
          },
          "Trennvorrichtungen für den Haustiertransport": {
            label: "Trennvorrichtungen für den Haustiertransport",
            value: "125"
          }
        }
      }
    }
  },
  "Bekleidung & Accessoires": {
    label: "Bekleidung & Accessoires",
    value: "126",
    children: {
      Bekleidung: {
        label: "Bekleidung",
        value: "127",
        children: {
          Sportbekleidung: {
            label: "Sportbekleidung",
            value: "128",
            children: {
              "Radsport-Funktionsbekleidung": {
                label: "Radsport-Funktionsbekleidung",
                value: "129",
                children: {
                  Fahrradträgerhosen: {
                    label: "Fahrradträgerhosen",
                    value: "130"
                  },
                  Fahrradtrikots: {
                    label: "Fahrradtrikots",
                    value: "131"
                  },
                  "Fahrradhosen (kurz)": {
                    label: "Fahrradhosen (kurz)",
                    value: "132"
                  },
                  "Radsport-Zeitfahranzüge": {
                    label: "Radsport-Zeitfahranzüge",
                    value: "133"
                  },
                  "Fahrradhosen (lang)": {
                    label: "Fahrradhosen (lang)",
                    value: "134"
                  }
                }
              },
              Boxshorts: {
                label: "Boxshorts",
                value: "135"
              },
              "Tanzkleider, Tanzröcke und Tanzkostüme": {
                label: "Tanzkleider, Tanzröcke und Tanzkostüme",
                value: "136"
              },
              "Hosen für American Football": {
                label: "Hosen für American Football",
                value: "137"
              },
              Jagdbekleidung: {
                label: "Jagdbekleidung",
                value: "138",
                children: {
                  "Ghillie-Anzüge": {
                    label: "Ghillie-Anzüge",
                    value: "139"
                  },
                  "Jagd- & Angelwesten": {
                    label: "Jagd- & Angelwesten",
                    value: "140"
                  },
                  "Jagd- & taktische Hosen": {
                    label: "Jagd- & taktische Hosen",
                    value: "141"
                  }
                }
              },
              Kampfsporthosen: {
                label: "Kampfsporthosen",
                value: "142"
              },
              Motorradbekleidung: {
                label: "Motorradbekleidung",
                value: "143",
                children: {
                  Motorradjacken: {
                    label: "Motorradjacken",
                    value: "144"
                  },
                  Motorradhosen: {
                    label: "Motorradhosen",
                    value: "145"
                  },
                  "Motorrad-Kombis": {
                    label: "Motorrad-Kombis",
                    value: "146"
                  }
                }
              },
              Paintballbekleidung: {
                label: "Paintballbekleidung",
                value: "147"
              }
            }
          },
          "Baby- & Kleinkindbekleidung": {
            label: "Baby- & Kleinkindbekleidung",
            value: "148",
            children: {
              "Unterteile für Babys & Kleinkinder": {
                label: "Unterteile für Babys & Kleinkinder",
                value: "149"
              },
              Babyüberhosen: {
                label: "Babyüberhosen",
                value: "150"
              },
              "Kleider für Babys & Kleinkinder": {
                label: "Kleider für Babys & Kleinkinder",
                value: "151"
              },
              "Baby- & Kleinkind-Oberbekleidung": {
                label: "Baby- & Kleinkind-Oberbekleidung",
                value: "152"
              },
              "Baby- & Kleinkind-Kombis": {
                label: "Baby- & Kleinkind-Kombis",
                value: "153"
              },
              "Baby-Schlafkleidung & -Schlafsäcke": {
                label: "Baby-Schlafkleidung & -Schlafsäcke",
                value: "154"
              },
              "Baby- & Kleinkindsocken": {
                label: "Baby- & Kleinkindsocken",
                value: "155"
              },
              "Bademode für Babys & Kleinkinder": {
                label: "Bademode für Babys & Kleinkinder",
                value: "156"
              },
              "Oberteile für Babys & Kleinkinder": {
                label: "Oberteile für Babys & Kleinkinder",
                value: "157"
              },
              "Baby-Bodys": {
                label: "Baby-Bodys",
                value: "158"
              },
              Kleinkindunterwäsche: {
                label: "Kleinkindunterwäsche",
                value: "159"
              }
            }
          },
          Kleider: {
            label: "Kleider",
            value: "160"
          },
          Einteiler: {
            label: "Einteiler",
            value: "161",
            children: {
              Overalls: {
                label: "Overalls",
                value: "162"
              },
              "Turn- & Gymnastikanzüge": {
                label: "Turn- & Gymnastikanzüge",
                value: "163"
              },
              Latzhosen: {
                label: "Latzhosen",
                value: "164"
              }
            }
          },
          Überbekleidung: {
            label: "Überbekleidung",
            value: "165",
            children: {
              Chaps: {
                label: "Chaps",
                value: "166"
              },
              "Mäntel & Jacken": {
                label: "Mäntel & Jacken",
                value: "167"
              },
              Regenhosen: {
                label: "Regenhosen",
                value: "168"
              },
              Regenanzüge: {
                label: "Regenanzüge",
                value: "169"
              },
              "Schneehosen und": {
                label: "Schneehosen und",
                value: "170"
              },
              Westen: {
                label: "Westen",
                value: "171"
              }
            }
          },
          Kombinationen: {
            label: "Kombinationen",
            value: "172"
          },
          Hosen: {
            label: "Hosen",
            value: "173"
          },
          "Shirts & Tops": {
            label: "Shirts & Tops",
            value: "174"
          },
          Shorts: {
            label: "Shorts",
            value: "175"
          },
          Röcke: {
            label: "Röcke",
            value: "176",
            children: {
              "Knielange Rücke": {
                label: "Knielange Rücke",
                value: "5583"
              },
              "Lange Röcke": {
                label: "Lange Röcke",
                value: "5584"
              },
              "Mini-Röcke": {
                label: "Mini-Röcke",
                value: "5585"
              }
            }
          },
          Skorts: {
            label: "Skorts",
            value: "177"
          },
          "Nachtwäsche & Loungewear": {
            label: "Nachtwäsche & Loungewear",
            value: "178",
            children: {
              Loungewear: {
                label: "Loungewear",
                value: "179"
              },
              Nachthemden: {
                label: "Nachthemden",
                value: "180"
              },
              Schlafanzüge: {
                label: "Schlafanzüge",
                value: "181"
              },
              Bademäntel: {
                label: "Bademäntel",
                value: "182"
              }
            }
          },
          Anzüge: {
            label: "Anzüge",
            value: "183",
            children: {
              "Anzüge & Hosenanzüge": {
                label: "Anzüge & Hosenanzüge",
                value: "184"
              },
              Kostüme: {
                label: "Kostüme",
                value: "185"
              },
              Smokings: {
                label: "Smokings",
                value: "186"
              }
            }
          },
          Bademode: {
            label: "Bademode",
            value: "187"
          },
          "Traditionelle & Festkleidung": {
            label: "Traditionelle & Festkleidung",
            value: "188",
            children: {
              Dirndl: {
                label: "Dirndl",
                value: "189"
              },
              "Hakama-Hosen": {
                label: "Hakama-Hosen",
                value: "190"
              },
              "Traditionelle japanische formelle Kleidung": {
                label: "Traditionelle japanische formelle Kleidung",
                value: "191"
              },
              "Kimono-Oberbekleidung": {
                label: "Kimono-Oberbekleidung",
                value: "192",
                children: {
                  "Haori-Jacken": {
                    label: "Haori-Jacken",
                    value: "5586"
                  },
                  "Kimono-Mäntel": {
                    label: "Kimono-Mäntel",
                    value: "5587"
                  }
                }
              },
              Kimonos: {
                label: "Kimonos",
                value: "193",
                children: {
                  "Hochzeitliche Kimonos": {
                    label: "Hochzeitliche Kimonos",
                    value: "5588"
                  },
                  "Legere Kimonos": {
                    label: "Legere Kimonos",
                    value: "5589"
                  },
                  "Furisode-Kimonos": {
                    label: "Furisode-Kimonos",
                    value: "5590"
                  },
                  "Iromuji-Kimonos": {
                    label: "Iromuji-Kimonos",
                    value: "5591"
                  },
                  "Komon-Kimonos": {
                    label: "Komon-Kimonos",
                    value: "5592"
                  },
                  "Tomesode- und Houmongi-Kimonos": {
                    label: "Tomesode- und Houmongi-Kimonos",
                    value: "5593"
                  }
                }
              },
              "Religiöse Festkleidung": {
                label: "Religiöse Festkleidung",
                value: "194",
                children: {
                  "Tauf- & Kommunionskleider": {
                    label: "Tauf- & Kommunionskleider",
                    value: "195"
                  }
                }
              },
              Saris: {
                label: "Saris",
                value: "196"
              },
              "Trachten Lederhosen": {
                label: "Trachten Lederhosen",
                value: "197"
              },
              Yukata: {
                label: "Yukata",
                value: "198"
              }
            }
          },
          "Unterwäsche & Socken": {
            label: "Unterwäsche & Socken",
            value: "199",
            children: {
              "BH-Zubehör": {
                label: "BH-Zubehör",
                value: "200",
                children: {
                  "BH-Träger-Pads": {
                    label: "BH-Träger-Pads",
                    value: "201"
                  },
                  "BH-Träger & -Erweiterungen": {
                    label: "BH-Träger & -Erweiterungen",
                    value: "202"
                  },
                  "BH-Einlagen": {
                    label: "BH-Einlagen",
                    value: "203"
                  },
                  Brustaufkleber: {
                    label: "Brustaufkleber",
                    value: "204"
                  }
                }
              },
              Büstenhalter: {
                label: "Büstenhalter",
                value: "205"
              },
              Strumpfhosen: {
                label: "Strumpfhosen",
                value: "206"
              },
              Suspensorien: {
                label: "Suspensorien",
                value: "207"
              },
              Dessous: {
                label: "Dessous",
                value: "208"
              },
              "Dessous-Accessoires": {
                label: "Dessous-Accessoires",
                value: "209",
                children: {
                  Strumpfhalter: {
                    label: "Strumpfhalter",
                    value: "210"
                  },
                  Strumpfbänder: {
                    label: "Strumpfbänder",
                    value: "211"
                  }
                }
              },
              "Lange Unterhosen": {
                label: "Lange Unterhosen",
                value: "212"
              },
              "Petticoats & Rüschenhöschen": {
                label: "Petticoats & Rüschenhöschen",
                value: "213"
              },
              Shapewear: {
                label: "Shapewear",
                value: "214"
              },
              Socken: {
                label: "Socken",
                value: "215"
              },
              Unterhemden: {
                label: "Unterhemden",
                value: "216"
              },
              Unterwäsche: {
                label: "Unterwäsche",
                value: "217"
              },
              Unterhosen: {
                label: "Unterhosen",
                value: "218"
              }
            }
          },
          Uniformen: {
            label: "Uniformen",
            value: "219",
            children: {
              Arbeitshosen: {
                label: "Arbeitshosen",
                value: "220"
              },
              Fliegeruniformen: {
                label: "Fliegeruniformen",
                value: "221"
              },
              "Uniformen für das Restaurantgewerbe": {
                label: "Uniformen für das Restaurantgewerbe",
                value: "222",
                children: {
                  Kochmützen: {
                    label: "Kochmützen",
                    value: "223"
                  },
                  Kochjacken: {
                    label: "Kochjacken",
                    value: "224"
                  },
                  Kochhosen: {
                    label: "Kochhosen",
                    value: "225"
                  }
                }
              },
              Militäruniformen: {
                label: "Militäruniformen",
                value: "226"
              },
              Schuluniformen: {
                label: "Schuluniformen",
                value: "227"
              },
              "Sicherheitsdienst-Bekleidung": {
                label: "Sicherheitsdienst-Bekleidung",
                value: "228"
              },
              Sportuniformen: {
                label: "Sportuniformen",
                value: "229",
                children: {
                  Baseballuniformen: {
                    label: "Baseballuniformen",
                    value: "230"
                  },
                  Basketballuniformen: {
                    label: "Basketballuniformen",
                    value: "231"
                  },
                  "Cheerleading-Uniformen": {
                    label: "Cheerleading-Uniformen",
                    value: "232"
                  },
                  Kricketuniformen: {
                    label: "Kricketuniformen",
                    value: "233"
                  },
                  "Uniformen für American Football": {
                    label: "Uniformen für American Football",
                    value: "234"
                  },
                  Hockeyuniformen: {
                    label: "Hockeyuniformen",
                    value: "235"
                  },
                  Kampfsportuniformen: {
                    label: "Kampfsportuniformen",
                    value: "236"
                  },
                  Schiedsrichteruniformen: {
                    label: "Schiedsrichteruniformen",
                    value: "237"
                  },
                  Fußballuniformen: {
                    label: "Fußballuniformen",
                    value: "238"
                  },
                  Softballuniformen: {
                    label: "Softballuniformen",
                    value: "239"
                  },
                  Ringeruniformen: {
                    label: "Ringeruniformen",
                    value: "240"
                  }
                }
              },
              Ärztekittel: {
                label: "Ärztekittel",
                value: "241"
              }
            }
          },
          Brautmoden: {
            label: "Brautmoden",
            value: "242",
            children: {
              Brautjungfernkleider: {
                label: "Brautjungfernkleider",
                value: "243"
              },
              Brautkleider: {
                label: "Brautkleider",
                value: "244"
              }
            }
          }
        }
      },
      Bekleidungsaccessoires: {
        label: "Bekleidungsaccessoires",
        value: "245",
        children: {
          Armstulpen: {
            label: "Armstulpen",
            value: "246"
          },
          "Zubehör für Baby- & Kleinkindbekleidung": {
            label: "Zubehör für Baby- & Kleinkindbekleidung",
            value: "247",
            children: {
              "Gürtel für Babys & Kleinkinder": {
                label: "Gürtel für Babys & Kleinkinder",
                value: "248"
              },
              "Baby- & Kleinkindhandschuhe": {
                label: "Baby- & Kleinkindhandschuhe",
                value: "249"
              },
              "Kopfbedeckungen für Babys & Kleinkinder": {
                label: "Kopfbedeckungen für Babys & Kleinkinder",
                value: "250"
              },
              Babyschutzbekleidung: {
                label: "Babyschutzbekleidung",
                value: "251"
              }
            }
          },
          Sturmhauben: {
            label: "Sturmhauben",
            value: "252"
          },
          "Bandanas & Stofftücher": {
            label: "Bandanas & Stofftücher",
            value: "253",
            children: {
              Bandanas: {
                label: "Bandanas",
                value: "254"
              },
              Haarpflegetücher: {
                label: "Haarpflegetücher",
                value: "255"
              }
            }
          },
          Gürtelschnallen: {
            label: "Gürtelschnallen",
            value: "256"
          },
          Gürtel: {
            label: "Gürtel",
            value: "257"
          },
          "Braut-Accessoires": {
            label: "Braut-Accessoires",
            value: "258",
            children: {
              Brautschleier: {
                label: "Brautschleier",
                value: "259"
              }
            }
          },
          Knopfnieten: {
            label: "Knopfnieten",
            value: "260"
          },
          Kragenstäbchen: {
            label: "Kragenstäbchen",
            value: "261"
          },
          Manschettenknöpfe: {
            label: "Manschettenknöpfe",
            value: "262"
          },
          Fächer: {
            label: "Fächer",
            value: "263"
          },
          Ohrenschützer: {
            label: "Ohrenschützer",
            value: "264"
          },
          "Handschuhe & Fausthandschuhe": {
            label: "Handschuhe & Fausthandschuhe",
            value: "265"
          },
          Haaraccessoires: {
            label: "Haaraccessoires",
            value: "266",
            children: {
              Duttkissen: {
                label: "Duttkissen",
                value: "267"
              },
              Haarkämme: {
                label: "Haarkämme",
                value: "268"
              },
              Haarverlängerungen: {
                label: "Haarverlängerungen",
                value: "269"
              },
              Haargabeln: {
                label: "Haargabeln",
                value: "270"
              },
              Haarnetze: {
                label: "Haarnetze",
                value: "271"
              },
              "Haarklammern, -nadeln & -clips": {
                label: "Haarklammern, -nadeln & -clips",
                value: "272",
                children: {
                  Haarspangen: {
                    label: "Haarspangen",
                    value: "273"
                  },
                  "Haarspangen und -clips": {
                    label: "Haarspangen und -clips",
                    value: "274"
                  },
                  Haarklammern: {
                    label: "Haarklammern",
                    value: "275"
                  }
                }
              },
              Haarkränze: {
                label: "Haarkränze",
                value: "276"
              },
              Stirnbänder: {
                label: "Stirnbänder",
                value: "277"
              },
              Haargummis: {
                label: "Haargummis",
                value: "278"
              },
              Diademe: {
                label: "Diademe",
                value: "279"
              },
              "Perücken-Accessoires": {
                label: "Perücken-Accessoires",
                value: "280",
                children: {
                  Perückennetze: {
                    label: "Perückennetze",
                    value: "281"
                  },
                  Perückenkleber: {
                    label: "Perückenkleber",
                    value: "282"
                  }
                }
              },
              Perücken: {
                label: "Perücken",
                value: "283"
              }
            }
          },
          Muffe: {
            label: "Muffe",
            value: "284"
          },
          Einstecktücher: {
            label: "Einstecktücher",
            value: "285"
          },
          Hüte: {
            label: "Hüte",
            value: "286"
          },
          "Kopfbekleidung & -tücher": {
            label: "Kopfbekleidung & -tücher",
            value: "287",
            children: {
              Fascinators: {
                label: "Fascinators",
                value: "288"
              },
              Kopfschmuck: {
                label: "Kopfschmuck",
                value: "289"
              },
              Turbane: {
                label: "Turbane",
                value: "290"
              }
            }
          },
          Beinstulpen: {
            label: "Beinstulpen",
            value: "291"
          },
          Leis: {
            label: "Leis",
            value: "292"
          },
          "Schwangerschaftsgürtel & Bauchstützen": {
            label: "Schwangerschaftsgürtel & Bauchstützen",
            value: "293"
          },
          Halswärmer: {
            label: "Halswärmer",
            value: "294"
          },
          Krawatten: {
            label: "Krawatten",
            value: "295"
          },
          Ansteckbuttons: {
            label: "Ansteckbuttons",
            value: "296"
          },
          Schärpen: {
            label: "Schärpen",
            value: "297"
          },
          "Schals & Halstücher": {
            label: "Schals & Halstücher",
            value: "298",
            children: {
              Schals: {
                label: "Schals",
                value: "299"
              },
              Schultertücher: {
                label: "Schultertücher",
                value: "300"
              }
            }
          },
          Sonnenbrillen: {
            label: "Sonnenbrillen",
            value: "301"
          },
          Hosenträger: {
            label: "Hosenträger",
            value: "302"
          },
          Krawattennadeln: {
            label: "Krawattennadeln",
            value: "303"
          },
          "Accessoires für traditionelle Kleidung": {
            label: "Accessoires für traditionelle Kleidung",
            value: "304",
            children: {
              Obis: {
                label: "Obis",
                value: "305"
              },
              "Tabi-Socken": {
                label: "Tabi-Socken",
                value: "306"
              },
              "Unterwäsche für Kimonos": {
                label: "Unterwäsche für Kimonos",
                value: "5594"
              },
              "Obi-Accessoires": {
                label: "Obi-Accessoires",
                value: "5595"
              }
            }
          },
          Schweißbänder: {
            label: "Schweißbänder",
            value: "307"
          }
        }
      },
      "Kostüme & Accessoires": {
        label: "Kostüme & Accessoires",
        value: "308",
        children: {
          Kostümaccessoires: {
            label: "Kostümaccessoires",
            value: "309",
            children: {
              Glatzen: {
                label: "Glatzen",
                value: "310"
              },
              "Kostüm-Accessoire-Sets": {
                label: "Kostüm-Accessoire-Sets",
                value: "311"
              },
              "Umhänge für Kostüme": {
                label: "Umhänge für Kostüme",
                value: "312"
              },
              "Kostüm-Handschuhe": {
                label: "Kostüm-Handschuhe",
                value: "313"
              },
              Kostümhüte: {
                label: "Kostümhüte",
                value: "314"
              },
              "Spezial-Schminkeffekte": {
                label: "Spezial-Schminkeffekte",
                value: "315"
              },
              Tabakwarenimitate: {
                label: "Tabakwarenimitate",
                value: "316"
              },
              Plastikschmuck: {
                label: "Plastikschmuck",
                value: "317"
              }
            }
          },
          Kostümschuhe: {
            label: "Kostümschuhe",
            value: "318"
          },
          "Kostüme & Verkleidungen": {
            label: "Kostüme & Verkleidungen",
            value: "319"
          },
          Masken: {
            label: "Masken",
            value: "320"
          }
        }
      },
      "Handtaschen & Geldbörsenaccessoires": {
        label: "Handtaschen & Geldbörsenaccessoires",
        value: "321",
        children: {
          Scheckheftetuis: {
            label: "Scheckheftetuis",
            value: "322"
          },
          Schlüsselanhänger: {
            label: "Schlüsselanhänger",
            value: "323"
          },
          Tragebänder: {
            label: "Tragebänder",
            value: "324"
          },
          Brieftaschenketten: {
            label: "Brieftaschenketten",
            value: "325"
          }
        }
      },
      "Handtaschen, Geldbörsen & Etuis": {
        label: "Handtaschen, Geldbörsen & Etuis",
        value: "326",
        children: {
          Ausweistaschen: {
            label: "Ausweistaschen",
            value: "327"
          },
          Visitenkartenetuis: {
            label: "Visitenkartenetuis",
            value: "328"
          },
          Handtaschen: {
            label: "Handtaschen",
            value: "329"
          },
          "Geldbeutel & Geldklammern": {
            label: "Geldbeutel & Geldklammern",
            value: "330"
          }
        }
      },
      Schmuck: {
        label: "Schmuck",
        value: "331",
        children: {
          Fußkettchen: {
            label: "Fußkettchen",
            value: "332"
          },
          Körperschmuck: {
            label: "Körperschmuck",
            value: "333"
          },
          Armbänder: {
            label: "Armbänder",
            value: "334"
          },
          "Broschen und Anstecknadeln": {
            label: "Broschen und Anstecknadeln",
            value: "335"
          },
          "Charms & Anhänger": {
            label: "Charms & Anhänger",
            value: "336"
          },
          Ohrringe: {
            label: "Ohrringe",
            value: "337"
          },
          Schmucksets: {
            label: "Schmucksets",
            value: "338"
          },
          Halsketten: {
            label: "Halsketten",
            value: "339"
          },
          Ringe: {
            label: "Ringe",
            value: "340"
          },
          Uhrenaccessoires: {
            label: "Uhrenaccessoires",
            value: "341",
            children: {
              Uhrenarmbänder: {
                label: "Uhrenarmbänder",
                value: "342"
              },
              "Aufkleber für Uhren": {
                label: "Aufkleber für Uhren",
                value: "343"
              },
              Uhrenbeweger: {
                label: "Uhrenbeweger",
                value: "344"
              }
            }
          },
          "Armbanduhren & Taschenuhren": {
            label: "Armbanduhren & Taschenuhren",
            value: "345"
          }
        }
      },
      "Schuh-Accessoires": {
        label: "Schuh-Accessoires",
        value: "346",
        children: {
          Stiefelsocken: {
            label: "Stiefelsocken",
            value: "347"
          },
          Gamaschen: {
            label: "Gamaschen",
            value: "348"
          },
          Überschuhe: {
            label: "Überschuhe",
            value: "349"
          },
          Schnürsenkel: {
            label: "Schnürsenkel",
            value: "350"
          },
          Sporen: {
            label: "Sporen",
            value: "351"
          }
        }
      },
      Schuhe: {
        label: "Schuhe",
        value: "352"
      }
    }
  },
  "Baby & Kleinkind": {
    label: "Baby & Kleinkind",
    value: "852",
    children: {
      "Baby Badebedarf": {
        label: "Baby Badebedarf",
        value: "853",
        children: {
          Babybadewannen: {
            label: "Babybadewannen",
            value: "854"
          },
          "Shampoo-Schutzschilder": {
            label: "Shampoo-Schutzschilder",
            value: "855"
          }
        }
      },
      "Geschenksets für Babys": {
        label: "Geschenksets für Babys",
        value: "856"
      },
      "Baby Gesundheitsbedarf": {
        label: "Baby Gesundheitsbedarf",
        value: "857",
        children: {
          "Baby-Gesundheits- & Pflegesets": {
            label: "Baby-Gesundheits- & Pflegesets",
            value: "858"
          },
          Nasensauger: {
            label: "Nasensauger",
            value: "859"
          },
          "Schnullerclips & -ketten": {
            label: "Schnullerclips & -ketten",
            value: "860"
          },
          "Reinigungstücher für Schnuller": {
            label: "Reinigungstücher für Schnuller",
            value: "861"
          },
          "Schnuller & Beißringe": {
            label: "Schnuller & Beißringe",
            value: "862"
          }
        }
      },
      Babysicherheit: {
        label: "Babysicherheit",
        value: "863",
        children: {
          "Zubehör für Baby- & Haustierschutzgitter": {
            label: "Zubehör für Baby- & Haustierschutzgitter",
            value: "864"
          },
          "Baby- & Haustierschutzgitter": {
            label: "Baby- & Haustierschutzgitter",
            value: "865"
          },
          Babyfone: {
            label: "Babyfone",
            value: "866"
          },
          "Babysicherheitsgurte & -leinen": {
            label: "Babysicherheitsgurte & -leinen",
            value: "867"
          },
          "Babysicherungen & Schutzvorrichtungen": {
            label: "Babysicherungen & Schutzvorrichtungen",
            value: "868"
          },
          "Baby-Schutzleisten": {
            label: "Baby-Schutzleisten",
            value: "869"
          }
        }
      },
      Babyspielwaren: {
        label: "Babyspielwaren",
        value: "870",
        children: {
          "ABC-Lernspielzeuge": {
            label: "ABC-Lernspielzeuge",
            value: "871"
          },
          "Baby-Aktiv-Spielzeug": {
            label: "Baby-Aktiv-Spielzeug",
            value: "872"
          },
          Babywippen: {
            label: "Babywippen",
            value: "873"
          },
          "Babyschaukeln & Türhopser": {
            label: "Babyschaukeln & Türhopser",
            value: "874"
          },
          "Baby-Mobile-Zubehör": {
            label: "Baby-Mobile-Zubehör",
            value: "875"
          },
          "Baby-Mobiles": {
            label: "Baby-Mobiles",
            value: "876"
          },
          "Schnuller & Beruhigung": {
            label: "Schnuller & Beruhigung",
            value: "877"
          },
          Babylauflernhilfen: {
            label: "Babylauflernhilfen",
            value: "878"
          },
          "Spiel- & Krabbeldecken": {
            label: "Spiel- & Krabbeldecken",
            value: "879",
            children: {
              Spielbogen: {
                label: "Spielbogen",
                value: "880"
              },
              Spielmatten: {
                label: "Spielmatten",
                value: "881"
              }
            }
          },
          "Lauf- & Spielgitter": {
            label: "Lauf- & Spielgitter",
            value: "882"
          },
          "Zieh- & Schiebespielzeug": {
            label: "Zieh- & Schiebespielzeug",
            value: "883"
          },
          Babyrasseln: {
            label: "Babyrasseln",
            value: "884"
          },
          "Sortier-, Stapel- & Steckspielzeug": {
            label: "Sortier-, Stapel- & Steckspielzeug",
            value: "885"
          }
        }
      },
      Babytransport: {
        label: "Babytransport",
        value: "886",
        children: {
          "Baby- & Kleinkindautositze": {
            label: "Baby- & Kleinkindautositze",
            value: "887"
          },
          Babyträger: {
            label: "Babyträger",
            value: "888"
          },
          Kinderwagen: {
            label: "Kinderwagen",
            value: "889"
          }
        }
      },
      Babytransportzubehör: {
        label: "Babytransportzubehör",
        value: "890",
        children: {
          "Zubehör für Baby- & Kleinkindautositze": {
            label: "Zubehör für Baby- & Kleinkindautositze",
            value: "891"
          },
          "Zubehör für Babyträger": {
            label: "Zubehör für Babyträger",
            value: "892"
          },
          "Zubehör für Kinderwagen": {
            label: "Zubehör für Kinderwagen",
            value: "893"
          },
          Fußsäcke: {
            label: "Fußsäcke",
            value: "894"
          },
          "Einkaufswagen- & Hochstuhlbezüge": {
            label: "Einkaufswagen- & Hochstuhlbezüge",
            value: "895"
          }
        }
      },
      Wickelbedarf: {
        label: "Wickelbedarf",
        value: "896",
        children: {
          "Behälter & Warmhalter für Baby-Reinigungstücher": {
            label: "Behälter & Warmhalter für Baby-Reinigungstücher",
            value: "897"
          },
          "Baby-Reinigungstücher": {
            label: "Baby-Reinigungstücher",
            value: "898"
          },
          "Wickelauflagen & Bezüge": {
            label: "Wickelauflagen & Bezüge",
            value: "899"
          },
          Wickelauflagen: {
            label: "Wickelauflagen",
            value: "900"
          },
          Windelsets: {
            label: "Windelsets",
            value: "901"
          },
          Windeleinlagen: {
            label: "Windeleinlagen",
            value: "902"
          },
          "Windel-Organizer": {
            label: "Windel-Organizer",
            value: "903"
          },
          Windeleimerzubehör: {
            label: "Windeleimerzubehör",
            value: "904"
          },
          Windeleimer: {
            label: "Windeleimer",
            value: "905"
          },
          "Behandlung von Windeldermatitis": {
            label: "Behandlung von Windeldermatitis",
            value: "906"
          },
          Windelbeutel: {
            label: "Windelbeutel",
            value: "907"
          },
          Windeln: {
            label: "Windeln",
            value: "908"
          }
        }
      },
      "Stillen & Füttern": {
        label: "Stillen & Füttern",
        value: "909",
        children: {
          "Nahrung für Babys & Kleinkinder": {
            label: "Nahrung für Babys & Kleinkinder",
            value: "910",
            children: {
              Babygetreide: {
                label: "Babygetreide",
                value: "911"
              },
              Babysäfte: {
                label: "Babysäfte",
                value: "912"
              },
              Babynahrung: {
                label: "Babynahrung",
                value: "913"
              },
              Milchnahrung: {
                label: "Milchnahrung",
                value: "914"
              },
              "Baby-Snacks": {
                label: "Baby-Snacks",
                value: "915"
              },
              "Getränke und Milchmischgetränke": {
                label: "Getränke und Milchmischgetränke",
                value: "916"
              }
            }
          },
          "Sauger für Babyflaschen": {
            label: "Sauger für Babyflaschen",
            value: "917",
            children: {
              Nuckelflascheneinsätze: {
                label: "Nuckelflascheneinsätze",
                value: "918"
              },
              Nuckelflaschenaufsätze: {
                label: "Nuckelflaschenaufsätze",
                value: "919"
              }
            }
          },
          Babyflaschen: {
            label: "Babyflaschen",
            value: "920"
          },
          "Babypflege-Timer": {
            label: "Babypflege-Timer",
            value: "921"
          },
          Lätzchen: {
            label: "Lätzchen",
            value: "922"
          },
          "Flaschenwärmer & Sterilisatoren": {
            label: "Flaschenwärmer & Sterilisatoren",
            value: "923"
          },
          Muttermilchbehälter: {
            label: "Muttermilchbehälter",
            value: "924"
          },
          Milchpumpenzubehör: {
            label: "Milchpumpenzubehör",
            value: "925"
          },
          Milchpumpen: {
            label: "Milchpumpen",
            value: "926"
          },
          "Baby-Spucktücher": {
            label: "Baby-Spucktücher",
            value: "927"
          },
          Stilltücher: {
            label: "Stilltücher",
            value: "928"
          },
          "Stillhütchen & -einlagen": {
            label: "Stillhütchen & -einlagen",
            value: "929"
          },
          Stillkissenbezüge: {
            label: "Stillkissenbezüge",
            value: "930"
          },
          Stillkissen: {
            label: "Stillkissen",
            value: "931"
          },
          Lerntassen: {
            label: "Lerntassen",
            value: "932"
          }
        }
      },
      Töpfchentraining: {
        label: "Töpfchentraining",
        value: "933",
        children: {
          Töpfchen: {
            label: "Töpfchen",
            value: "934"
          },
          Töpfchentrainingsets: {
            label: "Töpfchentrainingsets",
            value: "935"
          }
        }
      },
      "Puckdecken & Mulltücher": {
        label: "Puckdecken & Mulltücher",
        value: "936",
        children: {
          Mulltücher: {
            label: "Mulltücher",
            value: "937"
          },
          Mullwindeln: {
            label: "Mullwindeln",
            value: "938"
          }
        }
      }
    }
  },
  "Wirtschaft & Industrie": {
    label: "Wirtschaft & Industrie",
    value: "939",
    children: {
      "Werbung & Marketing": {
        label: "Werbung & Marketing",
        value: "940",
        children: {
          Broschüren: {
            label: "Broschüren",
            value: "941"
          },
          Messetheken: {
            label: "Messetheken",
            value: "942"
          },
          "Messestand-Displays": {
            label: "Messestand-Displays",
            value: "943"
          }
        }
      },
      Landwirtschaft: {
        label: "Landwirtschaft",
        value: "944",
        children: {
          Viehhaltung: {
            label: "Viehhaltung",
            value: "945",
            children: {
              Brutapparate: {
                label: "Brutapparate",
                value: "946"
              },
              Viehfutter: {
                label: "Viehfutter",
                value: "947",
                children: {
                  Rinderfutter: {
                    label: "Rinderfutter",
                    value: "948"
                  },
                  Hühnerfutter: {
                    label: "Hühnerfutter",
                    value: "949"
                  },
                  "Ziegen- und Schaffutter": {
                    label: "Ziegen- und Schaffutter",
                    value: "950"
                  },
                  "Futter für gemischte Herden": {
                    label: "Futter für gemischte Herden",
                    value: "951"
                  },
                  Schweinefutter: {
                    label: "Schweinefutter",
                    value: "952"
                  }
                }
              },
              Viehfutterspender: {
                label: "Viehfutterspender",
                value: "953"
              },
              Viehhalfter: {
                label: "Viehhalfter",
                value: "954"
              }
            }
          }
        }
      },
      Automatisierungskomponenten: {
        label: "Automatisierungskomponenten",
        value: "955",
        children: {
          "Speicherprogrammierbare Steuerungen": {
            label: "Speicherprogrammierbare Steuerungen",
            value: "956"
          },
          "Antriebssysteme mit variabler Frequenz & einstellbarer Drehzahl": {
            label:
              "Antriebssysteme mit variabler Frequenz & einstellbarer Drehzahl",
            value: "957"
          }
        }
      },
      Baugewerbe: {
        label: "Baugewerbe",
        value: "958",
        children: {
          Vermessung: {
            label: "Vermessung",
            value: "959"
          },
          Verkehrskegel: {
            label: "Verkehrskegel",
            value: "960"
          }
        }
      },
      Zahnmedizin: {
        label: "Zahnmedizin",
        value: "961",
        children: {
          Zahnzement: {
            label: "Zahnzement",
            value: "962"
          },
          "Zahnmedizinische Instrumente": {
            label: "Zahnmedizinische Instrumente",
            value: "963",
            children: {
              Dappengläser: {
                label: "Dappengläser",
                value: "964"
              },
              Dentalspiegel: {
                label: "Dentalspiegel",
                value: "965"
              },
              "Zahnmedizinische Instrumentensets": {
                label: "Zahnmedizinische Instrumentensets",
                value: "966"
              },
              "Prophy-Kelche": {
                label: "Prophy-Kelche",
                value: "967"
              },
              "Prophy-Köpfe": {
                label: "Prophy-Köpfe",
                value: "968"
              }
            }
          },
          "Prophy-Paste": {
            label: "Prophy-Paste",
            value: "969"
          }
        }
      },
      "Film & Fernsehen": {
        label: "Film & Fernsehen",
        value: "970"
      },
      "Finanzwesen & Versicherung": {
        label: "Finanzwesen & Versicherung",
        value: "971",
        children: {
          Bullion: {
            label: "Bullion",
            value: "972"
          }
        }
      },
      Nahrungsmittelservice: {
        label: "Nahrungsmittelservice",
        value: "973",
        children: {
          Backwarenbehälter: {
            label: "Backwarenbehälter",
            value: "974"
          },
          Spülwannen: {
            label: "Spülwannen",
            value: "975"
          },
          "Scheck- & Kartenetuis": {
            label: "Scheck- & Kartenetuis",
            value: "976"
          },
          "Takeaway-Behälter": {
            label: "Takeaway-Behälter",
            value: "977"
          },
          Einwegdeckel: {
            label: "Einwegdeckel",
            value: "978"
          },
          "Einweg-Serviergeschirr": {
            label: "Einweg-Serviergeschirr",
            value: "979",
            children: {
              Einwegtabletts: {
                label: "Einwegtabletts",
                value: "980"
              }
            }
          },
          Einweggeschirr: {
            label: "Einweggeschirr",
            value: "981",
            children: {
              Einwegschüsseln: {
                label: "Einwegschüsseln",
                value: "982"
              },
              Einwegbecher: {
                label: "Einwegbecher",
                value: "983"
              },
              Einwegbesteck: {
                label: "Einwegbesteck",
                value: "984"
              },
              Einwegteller: {
                label: "Einwegteller",
                value: "985"
              }
            }
          },
          Lebensmittelkörbe: {
            label: "Lebensmittelkörbe",
            value: "986"
          },
          Servierwagen: {
            label: "Servierwagen",
            value: "987"
          },
          Gemüseschleudern: {
            label: "Gemüseschleudern",
            value: "988"
          },
          "Hot-Dog-Grills": {
            label: "Hot-Dog-Grills",
            value: "989"
          },
          Eisbehälter: {
            label: "Eisbehälter",
            value: "990"
          },
          "Teller- & Speisenwärmer": {
            label: "Teller- & Speisenwärmer",
            value: "991"
          },
          Auslagenschutz: {
            label: "Auslagenschutz",
            value: "992"
          },
          Einwegbehälter: {
            label: "Einwegbehälter",
            value: "993"
          },
          "Kippbare Industriepfannen": {
            label: "Kippbare Industriepfannen",
            value: "994"
          },
          Verkaufsautomaten: {
            label: "Verkaufsautomaten",
            value: "995"
          }
        }
      },
      "Forst- & Holzwirtschaft": {
        label: "Forst- & Holzwirtschaft",
        value: "996"
      },
      "Friseur- & Kosmetikgewerbe": {
        label: "Friseur- & Kosmetikgewerbe",
        value: "997",
        children: {
          "Friseurumhänge & Halskrausen": {
            label: "Friseurumhänge & Halskrausen",
            value: "998"
          },
          Pedikürestuhl: {
            label: "Pedikürestuhl",
            value: "999"
          },
          Salonstühle: {
            label: "Salonstühle",
            value: "1000"
          }
        }
      },
      Schwermaschinen: {
        label: "Schwermaschinen",
        value: "1001",
        children: {
          Häcksler: {
            label: "Häcksler",
            value: "1002"
          }
        }
      },
      "Hotels & Gaststätten": {
        label: "Hotels & Gaststätten",
        value: "1003"
      },
      "Gewerbliche Lagerung": {
        label: "Gewerbliche Lagerung",
        value: "1004",
        children: {
          Industrieschränke: {
            label: "Industrieschränke",
            value: "1005"
          },
          Industrieregale: {
            label: "Industrieregale",
            value: "1006"
          },
          Frachtcontainer: {
            label: "Frachtcontainer",
            value: "1007"
          },
          "Zäune, Einfriedungen & Türen": {
            label: "Zäune, Einfriedungen & Türen",
            value: "1008"
          }
        }
      },
      "Aufbewahrungszubehör für industrielle Zwecke": {
        label: "Aufbewahrungszubehör für industrielle Zwecke",
        value: "1009"
      },
      Reinigungswagen: {
        label: "Reinigungswagen",
        value: "1010"
      },
      Strafverfolgung: {
        label: "Strafverfolgung",
        value: "1011",
        children: {
          Schellen: {
            label: "Schellen",
            value: "1012"
          },
          Metalldetektoren: {
            label: "Metalldetektoren",
            value: "1013"
          }
        }
      },
      Herstellung: {
        label: "Herstellung",
        value: "1014"
      },
      Materialbeförderung: {
        label: "Materialbeförderung",
        value: "1015",
        children: {
          Förderanlagen: {
            label: "Förderanlagen",
            value: "1016"
          },
          "Aufzüge & Hebeeinrichtungen": {
            label: "Aufzüge & Hebeeinrichtungen",
            value: "1017",
            children: {
              "Hebezüge, Kräne & Laufkatzen": {
                label: "Hebezüge, Kräne & Laufkatzen",
                value: "1018"
              },
              Hubwagen: {
                label: "Hubwagen",
                value: "1019"
              },
              Personenaufzüge: {
                label: "Personenaufzüge",
                value: "1020"
              },
              "Flaschenzüge & Rollen": {
                label: "Flaschenzüge & Rollen",
                value: "1021"
              },
              Seilwinden: {
                label: "Seilwinden",
                value: "1022"
              }
            }
          },
          "Paletten & Ladeflächen": {
            label: "Paletten & Ladeflächen",
            value: "1023"
          }
        }
      },
      "Medizinischer Bereich": {
        label: "Medizinischer Bereich",
        value: "1024",
        children: {
          "Krankenhaus-Vorhänge": {
            label: "Krankenhaus-Vorhänge",
            value: "1025"
          },
          Krankenhausbekleidung: {
            label: "Krankenhausbekleidung",
            value: "1026"
          },
          "Medizinische Bettwäsche": {
            label: "Medizinische Bettwäsche",
            value: "1027"
          },
          "Medizinische Geräte": {
            label: "Medizinische Geräte",
            value: "1028",
            children: {
              "Automatisierte externe Defibrillatoren": {
                label: "Automatisierte externe Defibrillatoren",
                value: "1029"
              },
              "Medizinische Hebegürtel": {
                label: "Medizinische Hebegürtel",
                value: "1030"
              },
              Reflexhämmer: {
                label: "Reflexhämmer",
                value: "1031"
              },
              Tragen: {
                label: "Tragen",
                value: "1032"
              },
              "Otoskope & Ophthalmoskope": {
                label: "Otoskope & Ophthalmoskope",
                value: "1033"
              },
              Patientenlifter: {
                label: "Patientenlifter",
                value: "1034"
              },
              Stethoskope: {
                label: "Stethoskope",
                value: "1035"
              },
              "Zubehör für Vitaldatenmonitore": {
                label: "Zubehör für Vitaldatenmonitore",
                value: "1036"
              },
              Vitaldatenmonitore: {
                label: "Vitaldatenmonitore",
                value: "1037"
              }
            }
          },
          "Medizinische Möbel": {
            label: "Medizinische Möbel",
            value: "1038",
            children: {
              "Chiropraktische Tische": {
                label: "Chiropraktische Tische",
                value: "1039"
              },
              "Untersuchungsstühle & -tische": {
                label: "Untersuchungsstühle & -tische",
                value: "1040"
              },
              "Krankenhaus- und Pflegebetten": {
                label: "Krankenhaus- und Pflegebetten",
                value: "1041"
              },
              Medikamentenaufbewahrung: {
                label: "Medikamentenaufbewahrung",
                value: "1042"
              },
              "Medizinische Wagen": {
                label: "Medizinische Wagen",
                value: "1043",
                children: {
                  Reanimationswagen: {
                    label: "Reanimationswagen",
                    value: "1044"
                  },
                  "Infusionsständer & -wagen": {
                    label: "Infusionsständer & -wagen",
                    value: "1045"
                  }
                }
              },
              "OP-Tische": {
                label: "OP-Tische",
                value: "1046"
              }
            }
          },
          "Medizinische Instrumente": {
            label: "Medizinische Instrumente",
            value: "1047",
            children: {
              "Medizinische Zangen": {
                label: "Medizinische Zangen",
                value: "1048"
              },
              Skalpellklingen: {
                label: "Skalpellklingen",
                value: "1049"
              },
              Skalpelle: {
                label: "Skalpelle",
                value: "1050"
              },
              "Chirurgische Nadeln und Fäden": {
                label: "Chirurgische Nadeln und Fäden",
                value: "1051"
              }
            }
          },
          "Medizinischer Bedarf": {
            label: "Medizinischer Bedarf",
            value: "1052",
            children: {
              Einweghandschuhe: {
                label: "Einweghandschuhe",
                value: "1053"
              },
              Fingerlinge: {
                label: "Fingerlinge",
                value: "1054"
              },
              "Nadeln & Spritzen": {
                label: "Nadeln & Spritzen",
                value: "1055",
                children: {
                  "Medizinische Kanülen- und Spritzensets": {
                    label: "Medizinische Kanülen- und Spritzensets",
                    value: "1056"
                  },
                  "Medizinische Kanülen": {
                    label: "Medizinische Kanülen",
                    value: "1057"
                  },
                  "Medizinische Spritzen": {
                    label: "Medizinische Spritzen",
                    value: "1058"
                  }
                }
              },
              Stomazubehör: {
                label: "Stomazubehör",
                value: "1059"
              },
              Mundspatel: {
                label: "Mundspatel",
                value: "1060"
              }
            }
          },
          "Medizinische Schulungsgeräte": {
            label: "Medizinische Schulungsgeräte",
            value: "1061",
            children: {
              "HLW-Puppen": {
                label: "HLW-Puppen",
                value: "1062"
              }
            }
          },
          "OP-Hauben": {
            label: "OP-Hauben",
            value: "1063"
          },
          "OP-Bekleidung": {
            label: "OP-Bekleidung",
            value: "1064"
          },
          "OP-Kittel": {
            label: "OP-Kittel",
            value: "1065"
          }
        }
      },
      "Bergbau & Steinbruch": {
        label: "Bergbau & Steinbruch",
        value: "1066"
      },
      "Piercing & Tätowieren": {
        label: "Piercing & Tätowieren",
        value: "1067",
        children: {
          Piercingbedarf: {
            label: "Piercingbedarf",
            value: "1068",
            children: {
              Piercingnadeln: {
                label: "Piercingnadeln",
                value: "1069"
              }
            }
          },
          Tätowierbedarf: {
            label: "Tätowierbedarf",
            value: "1070",
            children: {
              "Tattoo-Abdeckungen": {
                label: "Tattoo-Abdeckungen",
                value: "1071"
              },
              Tätowierfarben: {
                label: "Tätowierfarben",
                value: "1072"
              },
              Tätowiermaschinen: {
                label: "Tätowiermaschinen",
                value: "1073"
              },
              Tätowiernadeln: {
                label: "Tätowiernadeln",
                value: "1074"
              }
            }
          }
        }
      },
      Einzelhandel: {
        label: "Einzelhandel",
        value: "1075",
        children: {
          "Präsentationsständer für Bekleidung": {
            label: "Präsentationsständer für Bekleidung",
            value: "1076"
          },
          Schaufensterpuppen: {
            label: "Schaufensterpuppen",
            value: "1077"
          },
          Schaufensterpuppenteile: {
            label: "Schaufensterpuppenteile",
            value: "1078"
          },
          Geldhandhabung: {
            label: "Geldhandhabung",
            value: "1079",
            children: {
              Geldscheinprüfgeräte: {
                label: "Geldscheinprüfgeräte",
                value: "1080"
              },
              "Zubehör für Ladenkassen & POS-Terminals": {
                label: "Zubehör für Ladenkassen & POS-Terminals",
                value: "1081",
                children: {
                  Kassenschubladen: {
                    label: "Kassenschubladen",
                    value: "1082"
                  },
                  Kreditkartenterminals: {
                    label: "Kreditkartenterminals",
                    value: "1083"
                  },
                  Unterschriftenpads: {
                    label: "Unterschriftenpads",
                    value: "1084"
                  }
                }
              },
              "Ladenkassen & POS-Terminals": {
                label: "Ladenkassen & POS-Terminals",
                value: "1085",
                children: {
                  Registrierkassen: {
                    label: "Registrierkassen",
                    value: "1086"
                  },
                  "POS-Terminals": {
                    label: "POS-Terminals",
                    value: "1087"
                  }
                }
              },
              "Geldzählmaschinen für Münzen und Scheine": {
                label: "Geldzählmaschinen für Münzen und Scheine",
                value: "1088"
              },
              Geldwechsler: {
                label: "Geldwechsler",
                value: "1089"
              },
              Geldtaschen: {
                label: "Geldtaschen",
                value: "1090"
              },
              "Münzrollenpapier & Geldscheinbanderolen": {
                label: "Münzrollenpapier & Geldscheinbanderolen",
                value: "1091"
              }
            }
          },
          "Einkaufstüten aus Papier & Plastik": {
            label: "Einkaufstüten aus Papier & Plastik",
            value: "1092"
          },
          Preisauszeichnungsgeräte: {
            label: "Preisauszeichnungsgeräte",
            value: "1093"
          },
          Vitrinen: {
            label: "Vitrinen",
            value: "1094"
          },
          "Schaufensterdeko & -puppen": {
            label: "Schaufensterdeko & -puppen",
            value: "1095"
          }
        }
      },
      "Wissenschaft & Labor": {
        label: "Wissenschaft & Labor",
        value: "1096",
        children: {
          Biochemikalien: {
            label: "Biochemikalien",
            value: "1097"
          },
          Sezierinstrumente: {
            label: "Sezierinstrumente",
            value: "1098"
          },
          Laborchemikalien: {
            label: "Laborchemikalien",
            value: "1099"
          },
          Laborausstattung: {
            label: "Laborausstattung",
            value: "1100",
            children: {
              Autoklaven: {
                label: "Autoklaven",
                value: "1101"
              },
              Zentrifugen: {
                label: "Zentrifugen",
                value: "1102"
              },
              Trockeneismaschinen: {
                label: "Trockeneismaschinen",
                value: "1103"
              },
              Gefriertrockenmaschinen: {
                label: "Gefriertrockenmaschinen",
                value: "1104"
              },
              Labormixer: {
                label: "Labormixer",
                value: "1105"
              },
              "Labor-Gefrierschränke": {
                label: "Labor-Gefrierschränke",
                value: "1106"
              },
              Labortrichter: {
                label: "Labortrichter",
                value: "1107"
              },
              "Labor-Heizplatten": {
                label: "Labor-Heizplatten",
                value: "1108"
              },
              Laboröfen: {
                label: "Laboröfen",
                value: "1109"
              },
              Mikroskopzubehör: {
                label: "Mikroskopzubehör",
                value: "1110",
                children: {
                  Mikroskopkameras: {
                    label: "Mikroskopkameras",
                    value: "1111"
                  },
                  "Mikroskopokulare & Adapter": {
                    label: "Mikroskopokulare & Adapter",
                    value: "1112"
                  },
                  "Mikroskop-Objektivlinsen": {
                    label: "Mikroskop-Objektivlinsen",
                    value: "1113"
                  },
                  "Ersatzlampen für Mikroskope": {
                    label: "Ersatzlampen für Mikroskope",
                    value: "1114"
                  },
                  Objektträger: {
                    label: "Objektträger",
                    value: "1115"
                  }
                }
              },
              Mikroskope: {
                label: "Mikroskope",
                value: "1116"
              },
              Mikrotome: {
                label: "Mikrotome",
                value: "1117"
              },
              "Zubehör für Spektrometer": {
                label: "Zubehör für Spektrometer",
                value: "1118"
              },
              Spektrometer: {
                label: "Spektrometer",
                value: "1119"
              }
            }
          },
          Laborproben: {
            label: "Laborproben",
            value: "1120"
          },
          Laborbedarf: {
            label: "Laborbedarf",
            value: "1121",
            children: {
              Bechergläse: {
                label: "Bechergläse",
                value: "1122"
              },
              Messzylinder: {
                label: "Messzylinder",
                value: "1123"
              },
              Laborkolben: {
                label: "Laborkolben",
                value: "1124"
              },
              Petrischalen: {
                label: "Petrischalen",
                value: "1125"
              },
              Pipetten: {
                label: "Pipetten",
                value: "1126"
              },
              Reagenzglasständer: {
                label: "Reagenzglasständer",
                value: "1127"
              },
              Reagenzgläser: {
                label: "Reagenzgläser",
                value: "1128"
              },
              Spritzflaschen: {
                label: "Spritzflaschen",
                value: "1129"
              }
            }
          }
        }
      },
      Beschilderung: {
        label: "Beschilderung",
        value: "1130",
        children: {
          "Öffnungszeiten-Schilder": {
            label: "Öffnungszeiten-Schilder",
            value: "1131"
          },
          "Digitale Schilder": {
            label: "Digitale Schilder",
            value: "1132"
          },
          "Elektronische Schilder": {
            label: "Elektronische Schilder",
            value: "1133",
            children: {
              "LED-Schilder": {
                label: "LED-Schilder",
                value: "1134"
              },
              Neonschilder: {
                label: "Neonschilder",
                value: "1135"
              }
            }
          },
          "Notfall- & Notausgangsschilder": {
            label: "Notfall- & Notausgangsschilder",
            value: "1136"
          },
          Wegweiser: {
            label: "Wegweiser",
            value: "1137"
          },
          "Geöffnet- und Geschlossen-Schilder": {
            label: "Geöffnet- und Geschlossen-Schilder",
            value: "1138"
          },
          Parkplatzschilder: {
            label: "Parkplatzschilder",
            value: "1139"
          },
          Hinweisschilder: {
            label: "Hinweisschilder",
            value: "1140"
          },
          Einzelhandelsschilder: {
            label: "Einzelhandelsschilder",
            value: "1141"
          },
          Verkehrsschilder: {
            label: "Verkehrsschilder",
            value: "1142"
          },
          Warnschilder: {
            label: "Warnschilder",
            value: "1143"
          },
          Sicherheitsschilder: {
            label: "Sicherheitsschilder",
            value: "1144"
          },
          "Tragbare Schilder": {
            label: "Tragbare Schilder",
            value: "1145"
          }
        }
      },
      Arbeitsschutzausrüstung: {
        label: "Arbeitsschutzausrüstung",
        value: "1146",
        children: {
          "Kugelsichere Westen": {
            label: "Kugelsichere Westen",
            value: "1147"
          },
          "Gasmasken- & Atemschutzmaskenzubehör": {
            label: "Gasmasken- & Atemschutzmaskenzubehör",
            value: "1148"
          },
          Schutzhelme: {
            label: "Schutzhelme",
            value: "1149"
          },
          Chemikalienschutzanzüge: {
            label: "Chemikalienschutzanzüge",
            value: "1150"
          },
          Sicherheitsschürzen: {
            label: "Sicherheitsschürzen",
            value: "1151"
          },
          Schutzbrillen: {
            label: "Schutzbrillen",
            value: "1152"
          },
          Schutzmasken: {
            label: "Schutzmasken",
            value: "1153",
            children: {
              Staubmasken: {
                label: "Staubmasken",
                value: "1154"
              },
              "Feuerwehr-Atemschutzmasken": {
                label: "Feuerwehr-Atemschutzmasken",
                value: "1155"
              },
              "Gas- & Atemschutzmasken": {
                label: "Gas- & Atemschutzmasken",
                value: "1156"
              },
              "Medizinische Masken": {
                label: "Medizinische Masken",
                value: "1157"
              }
            }
          },
          Schutzhandschuhe: {
            label: "Schutzhandschuhe",
            value: "1158"
          },
          Knieschoner: {
            label: "Knieschoner",
            value: "1159"
          },
          Schweißhelme: {
            label: "Schweißhelme",
            value: "1160"
          },
          Sicherungsgurte: {
            label: "Sicherungsgurte",
            value: "1161"
          },
          Halteseile: {
            label: "Halteseile",
            value: "1162"
          }
        }
      }
    }
  },
  "Kameras & Optik": {
    label: "Kameras & Optik",
    value: "1163",
    children: {
      "Kamera- & Optisches Zubehör": {
        label: "Kamera- & Optisches Zubehör",
        value: "1164",
        children: {
          "Kamera-Ersatzkabel": {
            label: "Kamera-Ersatzkabel",
            value: "1165"
          },
          "Kamera- & Video-Objektive": {
            label: "Kamera- & Video-Objektive",
            value: "1166",
            children: {
              Kameraobjektive: {
                label: "Kameraobjektive",
                value: "1167"
              },
              "Objektive für Überwachungskameras": {
                label: "Objektive für Überwachungskameras",
                value: "1168"
              },
              "Videokamera-Objektive": {
                label: "Videokamera-Objektive",
                value: "1169"
              }
            }
          },
          "Zubehör für Kameraobjektive": {
            label: "Zubehör für Kameraobjektive",
            value: "1170",
            children: {
              "Objektiv- & Filteradapter": {
                label: "Objektiv- & Filteradapter",
                value: "1171"
              },
              Objektivtaschen: {
                label: "Objektivtaschen",
                value: "1172"
              },
              Objektivabdeckungen: {
                label: "Objektivabdeckungen",
                value: "1173"
              },
              Objektivkonverter: {
                label: "Objektivkonverter",
                value: "1174"
              },
              Objektivfilter: {
                label: "Objektivfilter",
                value: "1175"
              },
              Streulichtblenden: {
                label: "Streulichtblenden",
                value: "1176"
              }
            }
          },
          Kamerazubehör: {
            label: "Kamerazubehör",
            value: "1177",
            children: {
              "Kamerazubehör-Sets": {
                label: "Kamerazubehör-Sets",
                value: "1178"
              },
              "Kamerataschen & -koffer": {
                label: "Kamerataschen & -koffer",
                value: "1179"
              },
              "Ersatzgehäuseteile & -klappen": {
                label: "Ersatzgehäuseteile & -klappen",
                value: "1180"
              },
              "Digitale Rückteile": {
                label: "Digitale Rückteile",
                value: "1181"
              },
              Kamerafilme: {
                label: "Kamerafilme",
                value: "1182"
              },
              "Kamera-Blitzlichtzubehör": {
                label: "Kamera-Blitzlichtzubehör",
                value: "1183"
              },
              "Kamera-Blitzgeräte": {
                label: "Kamera-Blitzgeräte",
                value: "1184"
              },
              "Fokusvorrichtungen für Kameras": {
                label: "Fokusvorrichtungen für Kameras",
                value: "1185"
              },
              Kameraausrüstungen: {
                label: "Kameraausrüstungen",
                value: "1186"
              },
              Kamerahaltegriffe: {
                label: "Kamerahaltegriffe",
                value: "1187"
              },
              Bildsensoren: {
                label: "Bildsensoren",
                value: "1188"
              },
              "Zoomkomponenten für Objektive": {
                label: "Zoomkomponenten für Objektive",
                value: "1189"
              },
              "Kamera-Fernbedienungen": {
                label: "Kamera-Fernbedienungen",
                value: "1190"
              },
              "Ersatztasten & -knöpfe": {
                label: "Ersatztasten & -knöpfe",
                value: "1191"
              },
              "Ersatzbildschirme & -Displays": {
                label: "Ersatzbildschirme & -Displays",
                value: "1192"
              },
              "Schalldämmung & Sound Blimps für Kameras": {
                label: "Schalldämmung & Sound Blimps für Kameras",
                value: "1193"
              },
              "Kamerastabilisatoren und -stützen": {
                label: "Kamerastabilisatoren und -stützen",
                value: "1194"
              },
              Kameragurte: {
                label: "Kameragurte",
                value: "1195"
              },
              "Sonnenschutz für Kameras & Sucheraufsätze": {
                label: "Sonnenschutz für Kameras & Sucheraufsätze",
                value: "1196"
              },
              Blitzhaltesysteme: {
                label: "Blitzhaltesysteme",
                value: "1197"
              },
              Kameramonitore: {
                label: "Kameramonitore",
                value: "1198"
              },
              "Überwachungskamera-Zubehör": {
                label: "Überwachungskamera-Zubehör",
                value: "1199"
              },
              "Zubehör für Unterwasserkameragehäuse": {
                label: "Zubehör für Unterwasserkameragehäuse",
                value: "1200"
              },
              "Unterwassergehäuse für Kameras und Videokameras": {
                label: "Unterwassergehäuse für Kameras und Videokameras",
                value: "1201"
              },
              Videokameraleuchten: {
                label: "Videokameraleuchten",
                value: "1202"
              }
            }
          },
          "Optisches Zubehör": {
            label: "Optisches Zubehör",
            value: "1203",
            children: {
              "Fernglas- & Fernrohrzubehör": {
                label: "Fernglas- & Fernrohrzubehör",
                value: "1204"
              },
              "Taschen & Koffer für optische Instrumente": {
                label: "Taschen & Koffer für optische Instrumente",
                value: "1205"
              },
              "Zubehör für Entferungsmesser": {
                label: "Zubehör für Entferungsmesser",
                value: "1206"
              },
              "Zubehör für Spektive": {
                label: "Zubehör für Spektive",
                value: "1207"
              },
              "Zubehör für Teleskope": {
                label: "Zubehör für Teleskope",
                value: "1208"
              },
              Wärmebildzubehör: {
                label: "Wärmebildzubehör",
                value: "1209"
              },
              "Zubehör für Zielfernrohre": {
                label: "Zubehör für Zielfernrohre",
                value: "1210"
              }
            }
          },
          "Zubehör für Stative": {
            label: "Zubehör für Stative",
            value: "1211",
            children: {
              Stativaufbewahrung: {
                label: "Stativaufbewahrung",
                value: "1212"
              },
              Stativköpfe: {
                label: "Stativköpfe",
                value: "1213"
              },
              "Schellen & Halterungen für Stative": {
                label: "Schellen & Halterungen für Stative",
                value: "1214"
              },
              Stativgriffe: {
                label: "Stativgriffe",
                value: "1215"
              },
              Stativspinnen: {
                label: "Stativspinnen",
                value: "1216"
              }
            }
          },
          Stative: {
            label: "Stative",
            value: "1217"
          }
        }
      },
      Kameras: {
        label: "Kameras",
        value: "1218",
        children: {
          Boroskope: {
            label: "Boroskope",
            value: "1219"
          },
          Digitalkameras: {
            label: "Digitalkameras",
            value: "1220"
          },
          Einwegkameras: {
            label: "Einwegkameras",
            value: "1221"
          },
          Analogkameras: {
            label: "Analogkameras",
            value: "1222"
          },
          Überwachungskameras: {
            label: "Überwachungskameras",
            value: "1223"
          },
          Wildkameras: {
            label: "Wildkameras",
            value: "1224"
          },
          Videokameras: {
            label: "Videokameras",
            value: "1225"
          },
          Webcams: {
            label: "Webcams",
            value: "1226"
          }
        }
      },
      "Optische Geräte": {
        label: "Optische Geräte",
        value: "1227",
        children: {
          Ferngläser: {
            label: "Ferngläser",
            value: "1228"
          },
          Monokulare: {
            label: "Monokulare",
            value: "1229"
          },
          "Optische Entfernungsmesser": {
            label: "Optische Entfernungsmesser",
            value: "1230"
          },
          Fernrohre: {
            label: "Fernrohre",
            value: "1231",
            children: {
              Spektive: {
                label: "Spektive",
                value: "1232"
              },
              Teleskope: {
                label: "Teleskope",
                value: "1233"
              },
              "Waffenspektive & Zielfernrohre": {
                label: "Waffenspektive & Zielfernrohre",
                value: "1234"
              }
            }
          }
        }
      },
      Fotografie: {
        label: "Fotografie",
        value: "1235",
        children: {
          Dunkelkammer: {
            label: "Dunkelkammer",
            value: "1236",
            children: {
              "Ausrüstung, Entwicklung & Verarbeitung": {
                label: "Ausrüstung, Entwicklung & Verarbeitung",
                value: "1237",
                children: {
                  Reprostative: {
                    label: "Reprostative",
                    value: "1238"
                  },
                  Dunkelkammerbecken: {
                    label: "Dunkelkammerbecken",
                    value: "1239"
                  },
                  "Entwicklungstanks & -spiralen": {
                    label: "Entwicklungstanks & -spiralen",
                    value: "1240"
                  },
                  "Entwicklungsschalen, Wascher & Trockner": {
                    label: "Entwicklungsschalen, Wascher & Trockner",
                    value: "1241"
                  },
                  "Retuschierbedarf & -zubehör": {
                    label: "Retuschierbedarf & -zubehör",
                    value: "1242"
                  }
                }
              },
              Vergrößerungszubehör: {
                label: "Vergrößerungszubehör",
                value: "1243",
                children: {
                  Dunkelkammerstaffeleien: {
                    label: "Dunkelkammerstaffeleien",
                    value: "1244"
                  },
                  "Dunkelkammer-Timer": {
                    label: "Dunkelkammer-Timer",
                    value: "1245"
                  },
                  Fokussierhilfen: {
                    label: "Fokussierhilfen",
                    value: "1246"
                  },
                  Lichtmesser: {
                    label: "Lichtmesser",
                    value: "1247"
                  },
                  Fotovergrößerer: {
                    label: "Fotovergrößerer",
                    value: "1248"
                  }
                }
              },
              Fotochemikalien: {
                label: "Fotochemikalien",
                value: "1249"
              },
              Fotopapier: {
                label: "Fotopapier",
                value: "1250"
              },
              Dunkelkammerleuchten: {
                label: "Dunkelkammerleuchten",
                value: "1251"
              }
            }
          },
          "Beleuchtung & Studiobedarf": {
            label: "Beleuchtung & Studiobedarf",
            value: "1252",
            children: {
              "Zubehör für Belichtungsmesser": {
                label: "Zubehör für Belichtungsmesser",
                value: "1253"
              },
              Belichtungsmesser: {
                label: "Belichtungsmesser",
                value: "1254"
              },
              Studiohintergründe: {
                label: "Studiohintergründe",
                value: "1255"
              },
              "Zubehör für Studiobeleuchtung & Blitzgeräte": {
                label: "Zubehör für Studiobeleuchtung & Blitzgeräte",
                value: "1256"
              },
              Lichtsteuerung: {
                label: "Lichtsteuerung",
                value: "1257",
                children: {
                  Blitzdiffusoren: {
                    label: "Blitzdiffusoren",
                    value: "1258"
                  },
                  Blitzreflektoren: {
                    label: "Blitzreflektoren",
                    value: "1259"
                  },
                  "Lichtfilter & Filterfolien": {
                    label: "Lichtfilter & Filterfolien",
                    value: "1260"
                  },
                  Softboxen: {
                    label: "Softboxen",
                    value: "1261"
                  }
                }
              },
              "Studioscheinwerfer & -blitzgeräte": {
                label: "Studioscheinwerfer & -blitzgeräte",
                value: "1262"
              },
              "Zubehör für Studioständer & -halterungen": {
                label: "Zubehör für Studioständer & -halterungen",
                value: "1263"
              },
              "Studio-Halterungen": {
                label: "Studio-Halterungen",
                value: "1264"
              }
            }
          },
          "Passepartouts & Aufziehmaterialien": {
            label: "Passepartouts & Aufziehmaterialien",
            value: "1265"
          },
          "Aufbewahrung für Negative & Kontaktbögen": {
            label: "Aufbewahrung für Negative & Kontaktbögen",
            value: "1266"
          }
        }
      }
    }
  },
  Möbel: {
    label: "Möbel",
    value: "2049",
    children: {
      "Baby- & Kleinkindmöbel": {
        label: "Baby- & Kleinkindmöbel",
        value: "2050",
        children: {
          "Möbelgarnituren für Babies & Kleinkinder": {
            label: "Möbelgarnituren für Babies & Kleinkinder",
            value: "2051"
          },
          "Wiegen- & Stubenwagenzubehör": {
            label: "Wiegen- & Stubenwagenzubehör",
            value: "2052"
          },
          "Wiegen & Stubenwagen": {
            label: "Wiegen & Stubenwagen",
            value: "2053"
          },
          Wickeltische: {
            label: "Wickeltische",
            value: "2054"
          },
          "Gitter- & Kinderbettzubehör": {
            label: "Gitter- & Kinderbettzubehör",
            value: "2055",
            children: {
              "Bettnestchen & Randschutz für Gitterbetten": {
                label: "Bettnestchen & Randschutz für Gitterbetten",
                value: "2056"
              },
              "Umbausätze für Gitterbetten": {
                label: "Umbausätze für Gitterbetten",
                value: "2057"
              }
            }
          },
          "Gitterbetten & Kleinkinderbetten": {
            label: "Gitterbetten & Kleinkinderbetten",
            value: "2058"
          },
          "Zubehör für Hochstühle und Sitzerhöhungen": {
            label: "Zubehör für Hochstühle und Sitzerhöhungen",
            value: "2059"
          },
          "Hochstühle & Sitzerhöhungen": {
            label: "Hochstühle & Sitzerhöhungen",
            value: "2060"
          }
        }
      },
      "Betten & Zubehör": {
        label: "Betten & Zubehör",
        value: "2061",
        children: {
          "Bett- & Bettrahmenzubehör": {
            label: "Bett- & Bettrahmenzubehör",
            value: "2062"
          },
          "Betten & Bettgestelle": {
            label: "Betten & Bettgestelle",
            value: "2063"
          },
          "Kopf- & Fußenden": {
            label: "Kopf- & Fußenden",
            value: "2064"
          },
          "Matratzen-Untergestelle": {
            label: "Matratzen-Untergestelle",
            value: "2065"
          },
          Matratzen: {
            label: "Matratzen",
            value: "2066"
          }
        }
      },
      Sitzbänke: {
        label: "Sitzbänke",
        value: "2067",
        children: {
          "Küchen- & Eckbänke": {
            label: "Küchen- & Eckbänke",
            value: "2068"
          },
          "Truhen- & Flurbänke": {
            label: "Truhen- & Flurbänke",
            value: "2069"
          },
          Schminktischhocker: {
            label: "Schminktischhocker",
            value: "2070"
          }
        }
      },
      Schränke: {
        label: "Schränke",
        value: "2071",
        children: {
          Kleiderschränke: {
            label: "Kleiderschränke",
            value: "2072"
          },
          "Buffets & Sideboards": {
            label: "Buffets & Sideboards",
            value: "2073"
          },
          Geschirrschränke: {
            label: "Geschirrschränke",
            value: "2074"
          },
          Kommoden: {
            label: "Kommoden",
            value: "2075"
          },
          Aktenschränke: {
            label: "Aktenschränke",
            value: "2076"
          },
          Bügelcenter: {
            label: "Bügelcenter",
            value: "2077"
          },
          Küchenschränke: {
            label: "Küchenschränke",
            value: "2078"
          },
          Zeitschriftenständer: {
            label: "Zeitschriftenständer",
            value: "2079"
          },
          Mediaaufbewahrung: {
            label: "Mediaaufbewahrung",
            value: "2080"
          },
          Büroschränke: {
            label: "Büroschränke",
            value: "2081"
          },
          Aufbewahrungstruhen: {
            label: "Aufbewahrungstruhen",
            value: "2082",
            children: {
              Aussteuertruhen: {
                label: "Aussteuertruhen",
                value: "2083"
              },
              Spielzeugtruhen: {
                label: "Spielzeugtruhen",
                value: "2084"
              }
            }
          },
          "Wasch- und Schminktische": {
            label: "Wasch- und Schminktische",
            value: "2085",
            children: {
              Badezimmerwaschtische: {
                label: "Badezimmerwaschtische",
                value: "2086"
              },
              Schminktische: {
                label: "Schminktische",
                value: "2087"
              }
            }
          },
          Spirituosenschränke: {
            label: "Spirituosenschränke",
            value: "2088"
          },
          Weinregale: {
            label: "Weinregale",
            value: "2089"
          }
        }
      },
      "Kücheninseln & Küchenwagen": {
        label: "Kücheninseln & Küchenwagen",
        value: "2090",
        children: {
          Küchenwagen: {
            label: "Küchenwagen",
            value: "2091"
          },
          Kücheninseln: {
            label: "Kücheninseln",
            value: "2092"
          }
        }
      },
      Stuhlzubehör: {
        label: "Stuhlzubehör",
        value: "2093",
        children: {
          "Ersatzteile für Hängesessel": {
            label: "Ersatzteile für Hängesessel",
            value: "2094"
          }
        }
      },
      Stühle: {
        label: "Stühle",
        value: "2095",
        children: {
          Sessel: {
            label: "Sessel",
            value: "2096"
          },
          Sitzsäcke: {
            label: "Sitzsäcke",
            value: "2097"
          },
          Liegemöbel: {
            label: "Liegemöbel",
            value: "2098"
          },
          "Elektrische Massagesessel": {
            label: "Elektrische Massagesessel",
            value: "2099"
          },
          Bodenstühle: {
            label: "Bodenstühle",
            value: "2100"
          },
          "Klappstühle & Holzhocker": {
            label: "Klappstühle & Holzhocker",
            value: "2101"
          },
          Multimediasessel: {
            label: "Multimediasessel",
            value: "2102"
          },
          Hängesessel: {
            label: "Hängesessel",
            value: "2103"
          },
          "Küchen- und Esszimmerstühle": {
            label: "Küchen- und Esszimmerstühle",
            value: "2104"
          },
          Schaukelstühle: {
            label: "Schaukelstühle",
            value: "2105"
          },
          "Sessel ohne Armlehnen": {
            label: "Sessel ohne Armlehnen",
            value: "2106"
          },
          "Hocker & Barhocker": {
            label: "Hocker & Barhocker",
            value: "2107"
          }
        }
      },
      "Möbel für Unterhaltungselektronik": {
        label: "Möbel für Unterhaltungselektronik",
        value: "2108"
      },
      Möbelgarnituren: {
        label: "Möbelgarnituren",
        value: "2109",
        children: {
          Badezimmergarnituren: {
            label: "Badezimmergarnituren",
            value: "2110"
          },
          Schlafzimmergarnituren: {
            label: "Schlafzimmergarnituren",
            value: "2111"
          },
          "Küchen- & Esszimmergarnituren": {
            label: "Küchen- & Esszimmergarnituren",
            value: "2112"
          },
          Wohnzimmergarnituren: {
            label: "Wohnzimmergarnituren",
            value: "2113"
          }
        }
      },
      Futonrahmen: {
        label: "Futonrahmen",
        value: "2114"
      },
      Futonauflagen: {
        label: "Futonauflagen",
        value: "2115"
      },
      Futons: {
        label: "Futons",
        value: "2116"
      },
      Büromöbel: {
        label: "Büromöbel",
        value: "2117",
        children: {
          Schreibtische: {
            label: "Schreibtische",
            value: "2118"
          },
          "Büro- & Schreibtischstühle": {
            label: "Büro- & Schreibtischstühle",
            value: "2119"
          },
          Büromöbelgarnituren: {
            label: "Büromöbelgarnituren",
            value: "2120"
          },
          Arbeitstische: {
            label: "Arbeitstische",
            value: "2121",
            children: {
              "Verstellbare Zeichentische": {
                label: "Verstellbare Zeichentische",
                value: "2122"
              },
              Konferenztische: {
                label: "Konferenztische",
                value: "2123"
              }
            }
          },
          "Arbeitsplätze im Großraumbüro": {
            label: "Arbeitsplätze im Großraumbüro",
            value: "2124"
          }
        }
      },
      "Zubehör für Büromöbel": {
        label: "Zubehör für Büromöbel",
        value: "2125",
        children: {
          "Schreibtischteile & -zubehör": {
            label: "Schreibtischteile & -zubehör",
            value: "2126"
          },
          "Bürostuhl-Zubehör": {
            label: "Bürostuhl-Zubehör",
            value: "2127"
          },
          "Zubehör für Arbeitsplätze im Großraumbüro": {
            label: "Zubehör für Arbeitsplätze im Großraumbüro",
            value: "2128"
          }
        }
      },
      Polsterhocker: {
        label: "Polsterhocker",
        value: "2129"
      },
      Gartenmöbel: {
        label: "Gartenmöbel",
        value: "2130",
        children: {
          Gartenbetten: {
            label: "Gartenbetten",
            value: "2131"
          },
          Gartenmöbelgarnituren: {
            label: "Gartenmöbelgarnituren",
            value: "2132"
          },
          Gartenhocker: {
            label: "Gartenhocker",
            value: "2133"
          },
          Gartensitzmöbel: {
            label: "Gartensitzmöbel",
            value: "2134",
            children: {
              Gartenbänke: {
                label: "Gartenbänke",
                value: "2135"
              },
              Gartenstühle: {
                label: "Gartenstühle",
                value: "2136"
              },
              Gartensofaelemente: {
                label: "Gartensofaelemente",
                value: "2137"
              },
              Gartensofas: {
                label: "Gartensofas",
                value: "2138"
              },
              Sonnenliegen: {
                label: "Sonnenliegen",
                value: "2139"
              }
            }
          },
          "Aufbewahrungsbehälter für draußen": {
            label: "Aufbewahrungsbehälter für draußen",
            value: "2140"
          },
          Gartentische: {
            label: "Gartentische",
            value: "2141"
          }
        }
      },
      Gartenmöbelzubehör: {
        label: "Gartenmöbelzubehör",
        value: "2142",
        children: {
          "Abdeckungen für Gartenmöbel": {
            label: "Abdeckungen für Gartenmöbel",
            value: "2143"
          }
        }
      },
      Raumteilerzubehör: {
        label: "Raumteilerzubehör",
        value: "2144"
      },
      Raumteiler: {
        label: "Raumteiler",
        value: "2145"
      },
      Regalsysteme: {
        label: "Regalsysteme",
        value: "2146",
        children: {
          "Bücherregale & Bücherschränke": {
            label: "Bücherregale & Bücherschränke",
            value: "2147"
          },
          "Wandregale & Simse": {
            label: "Wandregale & Simse",
            value: "2148"
          }
        }
      },
      Regalzubehör: {
        label: "Regalzubehör",
        value: "2149",
        children: {
          Ersatzregale: {
            label: "Ersatzregale",
            value: "2150"
          }
        }
      },
      "Sofa-Zubehör": {
        label: "Sofa-Zubehör",
        value: "2151",
        children: {
          "Sitzverstärkung für Stühle & Sofas": {
            label: "Sitzverstärkung für Stühle & Sofas",
            value: "2152"
          },
          Anbausofas: {
            label: "Anbausofas",
            value: "2153"
          }
        }
      },
      Sofas: {
        label: "Sofas",
        value: "2154"
      },
      Tischzubehör: {
        label: "Tischzubehör",
        value: "2155",
        children: {
          Tischbeine: {
            label: "Tischbeine",
            value: "2156"
          },
          Tischplatten: {
            label: "Tischplatten",
            value: "2157"
          }
        }
      },
      Tische: {
        label: "Tische",
        value: "2158",
        children: {
          Ziertische: {
            label: "Ziertische",
            value: "2159",
            children: {
              Couchtische: {
                label: "Couchtische",
                value: "2160"
              },
              Beistelltische: {
                label: "Beistelltische",
                value: "2161"
              },
              Konsolentische: {
                label: "Konsolentische",
                value: "2162"
              }
            }
          },
          Kinderspieltische: {
            label: "Kinderspieltische",
            value: "2163"
          },
          Klapptische: {
            label: "Klapptische",
            value: "2164"
          },
          "Küchen- & Esszimmertische": {
            label: "Küchen- & Esszimmertische",
            value: "2165"
          },
          Kotatsu: {
            label: "Kotatsu",
            value: "2166"
          },
          Nachttische: {
            label: "Nachttische",
            value: "2167"
          },
          "Poker- & Spieltische": {
            label: "Poker- & Spieltische",
            value: "2168"
          },
          Nähmaschinentische: {
            label: "Nähmaschinentische",
            value: "2169"
          }
        }
      }
    }
  },
  Geschenkgutscheine: {
    label: "Geschenkgutscheine",
    value: "5582"
  }
};
