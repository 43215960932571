import ApiService from "@/core/services/api.service";
const url = process.env.VUE_APP_API_ADMIN;

class Languages {
  getAll() {
    return ApiService.get(url, "languages");
  }
}

export default new Languages();
